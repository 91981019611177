<template>
    <div class="row">
        <b-form-group
            class="col-6"
            style="text-align: left"
            id="input-group-2"
            :label="$t('documents.typeId')+':'"
            label-for="input-2">
            <b-form-select
                :class="{'is-invalid':$v.typeFileSelection.$error, 'is-valid':!$v.typeFileSelection.$invalid}"
                @change="fileSelected"
                v-model="typeFileSelection"
                :value="typeFileSelection ? typeFileSelection : null"
                ref="typeFileSelection"
                :options="options"
                :disabled="documentExist"
                size="sm"
                class="mt-0"></b-form-select>
            </b-form-group>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
//disabled = (typeFileSelection == 'ine_ife' ? update.fileId && true : typeFileSelection == 'pasaporte' && update.fileId && true)
export default {
    name: 'DocumentSelection',
    props:{
        update: Object,
        hasDocument: Boolean
    },
    data(){
        return {
            ID: this.update ? this.update.ID : null,
            typeFileSelection: this.update ? this.update.typeFileSelection ? this.update.typeFileSelection : null : null,
            options: [
                { value: null, text: 'Seleccione una opcion...' },
                { value: 'ine_ife', text: this.$t('documents.ine') },
                { value: 'pasaporte', text: this.$t('documents.passport') },
            ],
            documentExist: false
        }
    },
    watch: { 
        update: function(newVal) { // watch it
            //console.log('Prop changed: ', newVal, ' | was: ', oldVal)
            this.ID= newVal.ID 
            this.typeFileSelection= newVal.typeFileSelection
            let exist = newVal.fileId ? newVal.fileId.filter(res => res.type == 'passport' || res.type == 'ine-back' || res.type == 'ine-front') : []
            
            if(newVal.typeFileSelection == 'ine_ife'){
                if(exist.length > 0){
                    this.documentExist = true
                }else{
                    this.documentExist = false
                }
            }else if(newVal.typeFileSelection == 'pasaporte'){
                if(exist.length == 1){
                    this.documentExist = true
                }else{
                    this.documentExist = false
                }
            }else{
                this.documentExist = false
            }
        },
    },
    validations: {
        typeFileSelection: {
            required
        },
        // ID:{
        //     required
        // },
    },
    methods: {
        fileSelected(){
            this.$emit('fileSelected', this.typeFileSelection)
        },
        onChangeDocument(){
            this.$emit('onChangeDocument', {ID:this.ID})
        }
    },
    updated(){
        //console.log(this.$store.state.dataUser)
    }
}
</script>
