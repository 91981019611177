<template>
  <b-card-footer class="p-0" style="position: sticky; bottom: 0; width: -webkit-fill-available;">
    <b-button @click="goToRoute" class="m-0 col-12" style="border-radius: 0; border: 1px solid #000;">
      <div style="display: inline-flex">
        <h4>{{titleFooter}}</h4>
        <div class="h3">
          <b-icon-arrow-left v-if="!killCheckIn && !finish" style="position: absolute; left: 5px;"/>
        </div>
      </div>
    </b-button>
    <loading-overlay :active="isLoading" :is-full-page="true" />
  </b-card-footer>
</template>

<script>
export default {
  name: 'checkinfooterEnd',
  props: {
    titleFooter: String,
    killCheckIn: Boolean,
    finish: Boolean,
  },
  data(){
    return {
      isLoading: false
    }
  },
  methods: {
    goToRoute(){
      this.isLoading = true
      if(this.finish){
        this.isLoading = false
        this.$router.push('/login')
      }else if(this.killCheckIn){
        this.isLoading = false
        // localStorage.removeItem('resToken')
        // localStorage.removeItem('idNewGuest')
        localStorage.removeItem('termsandconditions')
        this.$router.push('/thanks')
      }else{
        this.$router.back()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
