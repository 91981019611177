<template>
    <div class="mx-auto mt-2">
        <b-button class="m-2 step-btn" pill :variant="active == 1 ? 'primary' : 'outline-primary'">1</b-button>
        <b-button class="m-2 step-btn" pill :variant="active == 2 ? 'primary' : 'outline-primary'">2</b-button>
        <b-button class="m-2 step-btn" pill :variant="active == 3 ? 'primary' : 'outline-primary'">3</b-button>
        <b-button class="m-2 step-btn" pill :variant="active == 4 ? 'primary' : 'outline-primary'">4</b-button>
        <b-button class="m-2 step-btn" pill :variant="active == 5 ? 'primary' : 'outline-primary'">5</b-button>
    </div>
</template>

<script>
    export default {
        name: 'NavigationBar',
        props: {
            active: Number
        },
    }
</script>

<style scoped>
	.step-btn{
		width: 30px;
		height: 30px;
		display: inline-flex;
		justify-content: center;
		font-size: 18px;
		line-height: 16px;
		align-items: center;
	}
</style>
