<template>
    <div class="card m-1">
        <!-- <h6 v-if="!fileId">Se requiere subir archivos</h6> -->
        <div class="row">
            <div class="col-12 text-center">
                <label class="h6">{{ typeFile }}</label>
                <!-- <p class="h6">{{id ? id : '1234567890'}}</p> -->
                <div :class="file ? 'h1 card m-2 p-1 center' : fileId ? 'h1 card m-2 p-1 center' : 'empty h1 card m-2 p-1 center'" style="align-items: center;">
                    <div v-if="file || fileId && !isDeleted">
                        <b-icon-file-earmark-check variant="success" />
                    </div>
                    <div v-else>
                        <b-icon-file-break />
                    </div>
                    <h6 style="font-size: 9px">{{file ? file.name : '...'}}</h6>
                </div>
                <b-button v-show="fileId" :disabled="(fileId ? true : false) && isDeleted || (file ? true : false) || $store.state.owner"  @click="clearFiles" size="sm" variant="outline-danger">{{$t('fileUpload.delete')}}</b-button>
            </div>
        </div>
        <div class="btns">
            <b-button
                v-show="!fileId && !isMobile() ? true : false"
                :disabled="(fileId ? true : false)"
                @click="takePicture"
                class="input-btn mb-0"
                >{{$t('fileUpload.takePicture')}}
            </b-button>
            <label v-if="!fileId && isMobile() ? true : false" class="input-btn mb-0">
                {{$t('fileUpload.takePicture')}}
                <b-form-file
                    type="file"
                    v-model="file"
                    accept="image/*"
                    capture="camera"
                    plain/>
            </label>
            <label v-show="!fileId" class="input-btn ml-4 mb-0">
                {{$t('fileUpload.openFile')}}
                <b-form-file
                    size="sm"
                    accept="image/jpeg, image/png"
                    ref="file"
                    v-model="file"
                    :disabled="(fileId ? true : false) && !isDeleted"
                    plain>
                </b-form-file>
            </label>
        </div>
        <take-picture @picture="picture" :fileTypeId="fileTypeId"/>
        <loading-overlay :active="isLoading" :is-full-page="true" />
        <alert-countdown ref="alertCountdown"/>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import TakePicture from './TakePicture'
import AlertCountdown from '@/components/bodycard/alerts/AlertCountdown'

export default {
    name: 'FileUpload',
    components: {
        TakePicture,
      AlertCountdown,
    },
    props: {
        typeFile: String,
        id: Number,
        hasDocument: Boolean,
        fileId: Object,
        fileTypeId: String
    },
    data(){
        return {
            file: null,
            isLoading: false,
            isDeleted: false,
        }
    },
    watch: {
        file: function (val) {
            //this.$store.dispatch('setFileToUpload', val)
            this.$emit('onChangeFIle', val)
        },
    },
    validations: {
        file:{
            required
        },
    },
    methods: {
        isMobile(){
            if(navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone|iPad|iPod/i)){
                return true
            }else {
                return false
            }
        },
        clearFiles() {
            var id = this.$store.state.bookData.id

            if(this.fileId && !this.isDeleted){
                this.isLoading = true
                this.$root.authApi.delete(`files?id=${this.fileId.id}`)
                .then(res => {
                    this.isLoading = false
                    if(res.status == 200){
                        this.$refs['file'].reset()
                        this.isDeleted = true
                        this.$root.authApi.get(`guests?id=${id}`).then(res => {
                            res.data.id = id
                            this.$store.state.bookData = res.data
                            this.$emit('fileDeleted')
                        })
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.isLoading = false
                    let button = document.createElement('button')
                    button.type = 'button'
                    button.onclick = this.$refs.alertCountdown.showAlert();
                })
            }
            //this.$refs['file'].reset()
        },
        clearLocalFiles(){
            this.$refs['file'].reset()
        },
        takePicture(){
            this.$bvModal.show(`takePicture-${this.fileTypeId}`)
        },
        picture(img){
            this.file = img
        }
    },
    created(){
    }
}
</script>

<style scoped>
	.input-btn{
		padding: 4px;
        background-color: #6c757d;
        color: #ffffff;
        border: 2px solid #6c757d;
        border-radius: 4px;
        position: relative;
        cursor: pointer;
	}
	.btns{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.card {
		padding: 10px;
	}
    .text-readerPicture {
        position: relative;
        overflow: hidden;
        display: inline-grid;

        /* Fancy button looking */
        border: 2px solid #6c757d;
        border-radius: 2px;
        padding: 4px;
        color: #ffffff;
        background-color: #6c757d;
    }
    .text-reader {
        position: relative;
        overflow: hidden;
        display: inline-grid;

        /* Fancy button looking */
        border: 2px solid black;
        border-radius: 5px;
        padding: 8px 12px;
        cursor: pointer;
    }
    .input-btn input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
    }
    .text-readerPicture input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
    }
    .text-reader input:focus {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
    }
    .empty {
        border-color:red;
    }
</style>
