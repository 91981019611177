<template>
	<div class="row justify-content-md-center">
	<div class="col-md-6 col-lg-5 col-12">
		<b-form @submit.prevent="onSubmit" @reset="onReset" v-if="show">
		<b-card no-body :style="descriptionStyles">
		<check-in-header />
		<navigation-bar :active="3"/>
		<b-card-body style="min-height: 500px">
			<b-card-title>{{$t('books.rooms')}}</b-card-title>
			<b-card
				class="mt-2 room-card"
				v-for="(room,i) in rooms"
				:key="room.bookId"
			>
				<b-button pill variant="info" size="sm" class="share-btn" @click="getLink(room)"><b-icon-share/></b-button>
				<h5>#{{i+1}} {{room.name}} <small>{{room.paxesQuantity}} {{$t('books.people')}}</small></h5>
				<b-form-group
					v-for="(guest, key) in room.guests"
					:key="key"
					:label="`${$t('books.guest')} #${key + 1}`"
				>
					<b-input-group class="mt-1" size="sm">
					<b-input-group-prepend v-if="ownerId === guest.id">
						<b-input-group-text>{{$t('books.owner')}}</b-input-group-text>
					</b-input-group-prepend>
					<b-form-input
						id="input-2"
						v-model="guest.name"
						disabled
					/>
					<b-input-group-append style="z-index: 1;">
						<b-button  v-if="ownerId !== guest.id" variant="outline-success" @click.prevent="sendBookData(guest)">{{$t('buttons.edit')}}</b-button>
						<b-button variant="outline-danger" @click.prevent="showModal(guest)">{{$t('buttons.delete')}}</b-button>
					</b-input-group-append>
					</b-input-group>
				</b-form-group>
				<div class="buttons-box">
				<b-button v-if="noMoreGuests ? !noMoreGuests.includes(room.bookId): false" size="sm" pill @click="sendBookid(room.bookId)" variant="primary"><b-icon-plus /> {{$t('books.guest')}}</b-button>
				<b-button class="mt-2" v-if="(!ownerExist && (room.paxesQuantity > room.guests.length))" size="sm" pill @click="updateReservationOwner(room.bookId)" variant="warning"><b-icon-plus /> {{$t('buttons.addOwner')}}</b-button>
				</div>
			</b-card>
		</b-card-body>
		<check-in-footer-middle step="step-four" :nextValue="$t('footer.next')" :submitStatus="submitStatus"/>
		</b-card>
		</b-form>
	</div>
	<modal-add-guests @getGuests="getGuests"/>
	<b-modal
		id="modal-delete-user"
		ref="modal"
		title="Desea eliminar este invitado?"
		@hidden="resetModal"
		@ok="handleOk"
	>
		<p>{{nameGuest}}</p>
	</b-modal>
	<b-modal
		id="modal-2"
		hide-header
		ok-only
		:ok-title="$t('shareLink.return')"
		>
		<h4>{{$t('shareLink.title')}}</h4>
		<b-input-group size="sm" class="mt-3">
			<b-form-input :value="newLink"></b-form-input>
			<b-input-group-append>
			<b-button
				@click="copiarAlPortapapeles"
				variant="info"
				v-b-tooltip.click="'copied'">{{$t('shareLink.copy')}} <b-icon-link/></b-button>
			</b-input-group-append>
			<!-- <b-input-group-append> -->
			<!-- </b-input-group-append> -->
		</b-input-group>
		<br>
		<div class="text-center">
			<a class="btn btn-success btn-lg" target="_blank" :href="`https://api.whatsapp.com/send?text=visitanos%20en%20${newLink}`" data-action="share/whatsapp/share">
			<div id="whatsapp"></div>
			</a>
		</div>
	</b-modal>
	<loading-overlay :active="isLoading" :is-full-page="true" />
	<b-modal id="alert" hide-backdrop content-class="shadow" size="sm" ok-only>
		<p class="my-4">{{$t('books.ownermessageExist')}}</p>
	</b-modal>
	</div>
</template>

<script>
import CheckInHeader from '@/components/header/CheckInHeader'
import CheckInFooterMiddle from '@/components/footer/CheckInFooterMiddle'
import NavigationBar from '@/components/bodycard/navigationbar'

import ModalAddGuests from '@/components/bodycard/ModalAddGuests'

import { mapState } from 'vuex'

const screenHeight = screen.height;
// import { mapState } from 'vuex'

export default {
	name: 'step-three',
	components: {
	CheckInHeader,
	CheckInFooterMiddle,
	NavigationBar,
	ModalAddGuests
	},
	props: {},
	computed: {
		ownerId(){
			return this.reservation!==null? this.reservation.clientId : null
		},
		descriptionStyles: function () {
			return { minHeight: (screenHeight)+'px' };
		},
		...mapState(['reservation'])
	},
	data() {
	return {
		rooms : null,
		guests: '',
		show: true,
		submitStatus: null,
		getAllGuests: null,
		id: null,
		idDelete: null,
		nameGuest: '',
		newLink: '',
		isLoading: false,
		noMoreGuests: [],
		ownerExist: false
	}
	},
	methods: {
		getLink (room) {
			const token = localStorage.getItem("resToken")
			this.$bvModal.show('modal-2')
			this.newLink = document.location.origin+'/bookGuest/'+token+'/'+room.bookId
			this.copiarAlPortapapeles()
		},
		copiarAlPortapapeles() {
			var aux = document.createElement("input");
			aux.setAttribute("value", this.newLink);
			document.body.appendChild(aux);
			aux.select();
			document.execCommand("copy");
			document.body.removeChild(aux);
		},
		getGuests () {
			this.isLoading = true
			this.$root.authApi.get('books').then(async (res) => {
			this.rooms = res.data
			this.noMoreGuests = []
			await this.$root.loadReservation()

			this.ownerExist = false
			for(let i = 0; i < res.data.length; i++){
				if(!this.ownerExist){
				this.ownerExist = res.data[i].guests.find(res => res.id == this.reservation.clientId) ? true : false
				}

				if(res.data[i].paxesQuantity == res.data[i].guests.length){
				this.noMoreGuests.push(res.data[i].bookId)
				}else if((res.data[i].paxesQuantity - 1) == (res.data[i].guests.length) && !this.ownerExist){
				this.noMoreGuests.push(res.data[i].bookId)
				}
			}
			this.$store.state.ownerExist = this.ownerExist;
			this.isLoading = false
			// this.$store.commit('setReservation',res.data);
			}).catch(() => this.isLoading = false)
		},
		updateReservationOwner(bookId){
			const data = {
				bookId,
				clientId: this.$store.state.reservation.clientId,
			}
			this.isLoading = true
			this.$root.authApi.post(`guests`, data)
				.then(() => {
					this.getGuests();
				})
		},
		sendBookid(value) {
			this.$store.state.bookData = {}
			this.$store.state.owner = false
			this.$store.state.isModalActive = true
			this.$store.dispatch('sendBookId', value)
			this.$bvModal.show('modal-1')
		},
		sendBookData (value) {
			this.$root.authApi.get(`guests?id=${value.id}`)
			.then((res) => {
			if(value.id == this.reservation.clientId){
				this.$store.state.owner = true
			}else {
				this.$store.state.owner = false
			}
			this.$store.state.isModalActive = true
			res.data.id = value.id
			this.$store.state.bookData = res.data
			this.$store.state.stateGuestEvent = 'edit'
			this.$bvModal.show('modal-1')
			})
		},
		showModal (guest) {
			if(guest){
			this.idDelete = guest.id
			this.nameGuest = guest.name
			this.$bvModal.show('modal-delete-user')
			}
		},
		resetModal (){
			this.idDelete = null
		},
		handleOk(bvModalEvt) {
			// Prevent modal from closing
			bvModalEvt.preventDefault()
			// Trigger submit handler
			this.deleteGuestById()
		},
		deleteGuestById(){
			this.$root.authApi.delete(`guests?id=${this.idDelete}`)
			.then(() =>{
			this.$nextTick(() => {
				this.getGuests();
				this.$bvModal.hide('modal-delete-user')
			})
			})
		},
		onSubmit() {
			if(!this.ownerExist){
			//alert('Debe de agrega al titular de la reserva para continuar el pre check-in')
			this.$bvModal.show('alert')
			}else{
			this.$router.push('step-four')
			}
		},
		onReset(evt) {
			evt.preventDefault()
		},
		/*async ownerId(){
			await this.$root.loadReservation()
			return this.reservation.clientId
		},*/
	},
	created: function () {
		this.getGuests()
	},
	mounted(){
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}
}
</script>

<style scoped>
	.buttons-box{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.room-card{
		position: relative;
		padding: 0;
	}
	.room-card .card-body{
		padding: 10px;
	}
	.share-btn{
		position: absolute;
		top: -10px;
		right: -10px;
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	@supports (-webkit-mask: url("")) or (mask: url("")) {
	.alignElements {
		display: flex;
		align-items: center;
	}
	#whatsapp {
		-webkit-mask: url(../assets/fonts/whatsapp-brands.svg);
		mask: url(../assets/fonts/whatsapp-brands.svg);
		background: #ffffff;
		width: 16px;
		height: 18px;
		float: left;
		margin-right: 5px;
	}
	}
</style>
