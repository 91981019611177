import Vue from 'vue'
import Vuex from 'vuex'
import getUserData  from '@/services/user-data'

Vue.use(Vuex)

const store = new Vuex.Store({
    /***
     * Definiendo las propiedades del store
     */
    state: {
        resToken:null,
        dataUser: {},
        guestsInfo: {},
        bookId: 0,
        bookData: {},
        stateGuestEvent: '',
        billing: {},
        fileUpload: [],
        settings: null,
        reservation: null,
        lang:'en',
        isModalActive: false,
    },
    getters: {
        getBookId (state) {
            return state.bookId
        },
        getBookData (state) {
            return state.bookData
        }
    },
    mutations: {
        resToken(state,resToken){
            state.resToken = resToken
        },
        setSettings(state,settings){
            state.settings = {...settings}
        },
        setReservation(state,reservation){
            state.reservation = {...reservation}
        },
        /** metodos del estado */
        dataUser (state, data) {
            state.dataUser = data
        },
        setBookData(state, updateBook){
            state.bookData = updateBook
        },
        getGuests (state, payload = {}) {
            return state.guestsInfo = payload
        },
        billing (state, payload = {}) {
            return state.billing = payload
        },
        fileToUpload (state, payload = []) {
            return state.fileUpload = payload
        },
        generalSetting (state, payload = {}){
            return state.settings = payload
        },
        setLang (state, lang){
            state.lang = lang
        }
    },
    actions: {
        setResToken(context, token){
            context.commit('resToken',token)
            localStorage.setItem("resToken", context.state.resToken);
        },
        incrementAsync (context, payload) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    context.commit('increment', payload)
                    resolve()
                }, 2000)
            })
        },
        setFileToUpload (context, payload) {
            context.commit('fileToUpload', payload)
        },
        sendBookId (context, payload) {
            context.state.stateGuestEvent = 'create'
            context.state.bookId = payload
        },
        async sendBookData (context, payload) {
            context.state.stateGuestEvent = 'edit'
            await getUserData.getGuestById('guests', `?id=${payload.id}`).then((res) => {
                if(res.status === 200){
                    res.data.id = payload.id
                    context.state.bookData = res.data
                }
            })
        },
        async deleteGuestById (context, id) {
            await getUserData.deleteGuestById('guests', `?id=${id}`)
            .then(() => {
                context.dispatch('getAllGuests')
            })
        },
        async getDataUser (context) {
            const examples = await getUserData.getUserData('reservation')
            return new Promise((resolve) => {
                context.commit('dataUser', examples)
                resolve(examples)
            })
        },
        async getReservation (context, payload) {
            const reservation = await getUserData.getUserData('reservation', payload)
            .then(res => res)
            return reservation
        },
        async getGuests (context, params) {
            await getUserData.getGuests('books', params);
            context.commit('getGuests')
        },
        async getAllGuests (context) {
            const guests = await getUserData.getGuests('books');
            context.commit('getGuests', guests.data)
            return guests.data
        },
        async addUploadGuest (context, payload) {
            let params = payload.id ? '?id='+payload.id : ''
            return await getUserData.addUploadGuest('guests', params, payload.data).then(async (res) => {
                if(res.status === 200){
                    const value = await getUserData.getGuests('books')
                    context.commit('getGuests', value.data)
                    context.state.stateGuestEvent = ''
                    return res
                }else{
                    return res
                }
            })
        },
        async generalSettings (context) {
            return await getUserData.generalSettings('settings', '').then(async (res) => {
                if(res.status === 200){
                    context.commit('generalSetting', res.data)
                    return res
                }
            })
        },
        saveReservationholder (context, payload) {
            let params = payload.id ? payload.id : ''
            return getUserData.saveReservationholder('files', params, payload.file, payload).then((res) => {
                if(res.status === 200){
                    return res
                }
            })
        },
        saveReservationholderData (context, payload){
            let params = payload.id ? '?id='+payload.id : ''
            return getUserData.addUploadGuest('guests', params, payload.data).then((res) => {
                if(res.status === 200){
                    return res
                }
            })
        },
        deleteFileUser (context, payload) {
            let params = payload.id ? '?id='+payload.id : ''
            return getUserData.deleteFileUser('files', params).then((res) => {
                if(res.status === 200){
                    return res
                }
            })
        },
        finishprecheckin (){
            return getUserData.finishprecheckin('precheckin').then((res) => {
                if(res.status === 200){
                    return res
                }
            })
        }
    }
})

export default store
