import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/pages/Home'

import CheckInContent from '@/layout/CheckInContent'
import WebCheckIn from '@/pages/WebCheckIn'
import PersonalInformation from '@/pages/DataExtension'
import AddGuests from '@/pages/AddGuests'
import AddGuestsInRoom from '@/pages/AddGuestsInRoom'
import TermsAndConditions from '@/pages/TermsAndConditions'
import Resume from '@/pages/Resume'
import Resume_guest from '@/pages/Resume_guest'
import Sign from '@/pages/Sign'
import Thanks from '@/pages/Thanks'
import Guest from '@/pages/Guest'
import Login from '@/pages/Login'
import ResAccess from '@/pages/ResAccess'

import NotFound from '@/pages/NotFound'

Vue.use(Router)

function backToLogin(to, from, next){
    const token = localStorage.getItem("resToken",null)
    if(token ===  null) {
        next('/login')
    }else{
        next()
    }
}

function guardToken(to, from, next){
    if(to.params){
        localStorage.setItem("resToken", to.params.token)
        localStorage.setItem("token", JSON.stringify(to.params))
        next()
    }
}
export default new Router({
    mode: 'history',
    routes: [
    {
        path: '/',
        name: 'home',
        component: Home
    },{
        path: '/res-access',
        name: 'res-access',
        component:ResAccess,
    },{
        path: '/login',
        name: 'Login',
        component: Login
    },{
        path: '/thanks',
        name: 'Thanks',
        component: Thanks
    },{
        path: '/guest',
        name: 'contentGuest',
        component: CheckInContent,
        children: [
            {
                path: ':token/:id/:name',
                beforeEnter:guardToken,
                name: 'Guest',
                component: Guest
            },
            {
                path: 'termsandconditions',
                name: 'termsandconditions',
                component: TermsAndConditions,
            },
            {
                path: 'resume',
                name: 'resume',
                component: Resume_guest,
            },
            {
                path: 'sign',
                name: 'sign',
                component: Sign,
            },
        ]
    },{
        path: '/bookGuest',
        name: 'contentBookGuest',
        component: CheckInContent,
        children: [
            {
                path: ':token/:id',
                beforeEnter:guardToken,
                name: 'bookGuest',
                component: AddGuestsInRoom
            },
        ]
    },{
        path: '/webcheckin',
        component: CheckInContent,
        beforeEnter: backToLogin,
        children: [
            {
                path: '/',
                name: 'webcheckin',
                component: WebCheckIn,
            },
            {
                path: 'step-two',
                name: 'step-two',
                component: PersonalInformation,
            },{
                path: 'step-three',
                name: 'step-tree',
                component: AddGuests,
            },{
                path: 'step-four',
                name: 'step-four',
                component: TermsAndConditions,
            },{
                path: 'resume',
                name: 'resume',
                component: Resume,
            },{
                path: 'sign',
                name: 'sign',
                component: Sign,
            },
        ]
    },
    { path: '*', component: NotFound }]
})
