<template>
  <div class="row justify-content-md-center">
    <div class="col-md-6 col-lg-5 col-12">
    <b-form @submit.stop.prevent="onSubmit" enctype="multipart/form-data" v-if="show">
      <b-card no-body>
        <check-in-header />
        <navigation-bar :active="2"/>
        <b-card-body style="min-height: 500px">
          <b-card-title>{{$t('personalInformation.titleInfo')}}</b-card-title>
          <div class="row">
                <div class="col-12">
                <div class="row">
                <b-form-group
                class="col-12"
                style="text-align: left"
                id="input-nombre-completo"
                :label="$t('personalInformation.fullName')"
                label-for="nombre-completo">
                <b-form-input
                    :disabled="true"
                    :class="{'is-invalid':$v.nombreCompleto.$error, 'is-valid':!$v.nombreCompleto.$invalid}"
                    id="nombre-completo"
                    size="sm"
                    v-model.trim="nombreCompleto"
                    ref="nombreCompleto"
                    placeholder=""
                ></b-form-input>
                </b-form-group>
                <b-form-group
                class="col-md-6 col-lg-6 col-12"
                style="text-align: left"
                id="input-group-2"
                :label="$t('personalInformation.email')"
                label-for="correo">
                <b-form-input
                    :class="{'is-invalid':$v.email.$error, 'is-valid':!$v.email.$invalid}"
                    id="correo"
                    size="sm"
                    v-model.trim="email"
                    @keyup="changeMode = true"
                    ref="email"
                    placeholder=""
                ></b-form-input>
                </b-form-group>
                <b-form-group
                class="col-md-6 col-lg-6 col-12"
                style="text-align: left"
                id="input-group-2"
                :label="$t('personalInformation.phone')"
                label-for="telefono">
                <b-form-input
                    :class="{'is-invalid':$v.phone.$error, 'is-valid':!$v.phone.$invalid}"
                    id="telefono"
                    size="sm"
                    v-model.trim="phone"
                    @keyup="changeMode = true"
                    ref="phone"
                    placeholder=""
                ></b-form-input>
                </b-form-group>
                <b-form-group
                    class="col-6"
                    style="text-align: left"
                    id="input-age"
                    :label="$t('personalInformation.age')"
                    label-for="age">
                    <b-form-input
                        @change="changeMode = true"
                        :class="{'is-invalid':$v.age.$error, 'is-valid':!$v.age.$invalid}"
                        id="age"
                        size="sm"
                        v-model="age"
                        ref="age"
                        placeholder=""
                    ></b-form-input>
                </b-form-group>
                </div>
                </div>
                <div class="row mt-3 ml-0 mr-1">
                  <div class="col-12">
                    <h5 class="" style="text-align: left">{{$t('address.titleAddress')}}</h5>
                  </div>
                </div>
                <direccion ref="direccionComponent" @onChangeDirection="onChangeDirection" :update="{
                                                                    country: this.country,
                                                                    city: this.city,
                                                                    zipCode: this.zipCode,
                                                                    address: this.address,
                                                                    state: this.state,
                                                                    address2: this.address2}" />
                <billing ref="billingComponent" @onChangeBilling="onChangeBilling" :update="billing ? billing : {
                                        country: country,
                                        city: city,
                                        zipCode: zipCode,
                                        address: address,
                                        state: state,
                                        address2: address2,
                                        name: nombre,
                                        lastName: apellido,
                                        email: email,
                                        phone: phone,
                                        nombreCompleto: nombreCompleto
                                        }"/>
                <div class="row mt-3 ml-0 mr-1">
                  <div class="col-12">
                  <h5 class="">{{$t('documents.titleDocuments')}}</h5>
                  <document-selection ref="documentSelection" :hasDocument="hasDocument" @fileSelected="fileSelected" @onChangeDocument="onChangeDocument" :update="documentUpdate"/>
                  </div>
                </div>
                <div v-if="typeFileSelection === 'ine_ife'" class="row mt-3 ml-0 mr-1">
                  <h5 class="col-12" style="text-align: left">{{$t('documents.file')}}</h5>
                  <div class="row">
                    <div class="col-12">
                      <file-upload
                        ref="fileIneFront"
                        typeFile="INE/IFE frente"
                        fileTypeId="INE/IFE_front"
                        :fileId="fileId ? fileId.find(({type}) => type === 'ine-front') : null"
                        :id="Number(ID)"
                        :hasDocument="hasDocument"
                        @onChangeFIle="onChangeFileIneFront"
                        @fileDeleted="fileDeleted" />
                    </div>
                    <div class="col-12">
                      <file-upload typeFile="INE/IFE atras" fileTypeId="INE/IFE_back" ref="fileIneBack" :fileId="fileId ? fileId.find(({type}) => type === 'ine-back') : null" :id="Number(ID)" :hasDocument="hasDocument" @onChangeFIle="onChangeFileIneBack" @fileDeleted="fileDeleted"/>
                    </div>
                  </div>
                </div>
                <div v-if="typeFileSelection === 'pasaporte'" class="col-12 mt-3 ml-0 mr-1">
                  <h5 style="text-align: left">{{$t('documents.file')}}</h5>
                  <file-upload :typeFile="$t('documents.passport')" ref="filepassport" fileTypeId="passport" :id="Number(ID)"  :fileId="fileId ? fileId.find(({type}) => type === 'passport') : null" :hasDocument="hasDocument" @onChangeFIle="onChangeFilePassport" @fileDeleted="fileDeleted"/>
                </div>
          </div>
        </b-card-body>
        <check-in-footer-middle step="step-three" :nextValue="$t('footer.next')" :submitStatus="submitStatus"/>
      </b-card>
      </b-form>
    </div>
    <loading-overlay :active="isLoading" :is-full-page="true" />
  </div>
</template>

<script>
import CheckInHeader from '@/components/header/CheckInHeader'
import CheckInFooterMiddle from '@/components/footer/CheckInFooterMiddle'
import NavigationBar from '@/components/bodycard/navigationbar'
import FileUpload from '@/components/bodycard/fileupload/FileUpload'
import DocumentSelection from '@/components/bodycard/DocumentSelection'

import Billing from '@/components/bodycard/Billing'
import Direccion from '@/components/bodycard/Direccion'

import { mapState } from 'vuex'

import { required, email, between } from 'vuelidate/lib/validators'

export default {
  name: 'step-two',
  components: {
    CheckInHeader,
    CheckInFooterMiddle,
    NavigationBar,
    FileUpload,
    DocumentSelection,
    Billing,
    Direccion
  },
  props: {},
  computed: {
    ...mapState(['fileUpload']),
  },
  data() {
    return {
      nombreCompleto: '',
      nombre: '',
      apellido: '',
      email: '',
      phone: null,
      address: '',
      address2: '',
      country: null,
      state: '',
      city: '',
      age: 0,
      zipCode: null,
      billing: {},
      checked: [],
      submitStatus: null,
      show: true,
      typeFileSelection: null,
      ID: null,
      hasDocument: false,
      documents: null,
      options: [
        { value: null, text: 'Please select an option' },
        { value: 'ine_ife', text: this.$t('documents.ine') },
        { value: 'pasaporte', text: this.$t('documents.passport') },
      ],
      isLoading: true,
      ineFileFront: null,
      ineFileBack: null,
      passportFile: null,
      changeMode: false,
      fileId: [],
      changeModeFile: false,
      disabledTypeFile: false,
      documentUpdate: {},
    }
  },
  validations: {
    nombreCompleto:{
      required
    },
    phone: {
        required
    },
    email: {
        required,
        email
    },
    age: {
        required,
        between: between(1, 99)
    }
  },
  methods: {
    async getUser(){
      let res = await this.$root.authApi.get('/reservations')
      this.$store.commit('setReservation',res.data);

      const {
        country,
        state,
        city,
        age,
        address,
        address2,
        zipCode,
        name,
        phone,
        email,
        lastName,
        personalId,
        personalIdType,
        hasDocument,
        documents,
        billing
      } = this.$store.state.reservation.client;
      this.age               = age != -1 ? age : 0
      this.country           = country
      this.city              = city
      this.state             = state
      this.address           = address
      this.address2          = address2
      this.zipCode           = zipCode
      this.nombre            = name
      this.apellido          = lastName
      this.phone             = phone
      this.email             = email
      this.ID                = personalId
      this.typeFileSelection = personalIdType
      this.hasDocument       = hasDocument
      this.documents         = documents
      this.fileId            = documents
      this.nombreCompleto    = name +' '+ lastName
      this.billing           = Object.keys(billing).length === 0 ? null : billing
      if(this.billing != null){
        this.$refs.billingComponent.status = true
      }
      this.documentUpdate = {
          ID: personalId,
          typeFileSelection: personalIdType,
          fileId: documents
      }
    },
    onChangeBilling(){
      this.changeMode = true
    },
    onChangeDirection(direction){
        this.changeMode = true
        this.address = direction.linea1
        this.address2 = direction.linea2
        this.country = direction.pais
        this.state = direction.estado
        this.city = direction.ciudad
        this.zipCode = direction.codigopostal
    },
    onChangeDocument(documents){
        this.changeMode = true
        this.ID = documents.ID
    },
    saveFile(ID){
        this.isLoading = true
          if(this.typeFileSelection != ''){
            if(this.changeModeFile){
              if(this.typeFileSelection === 'ine_ife'){
                if(this.ineFileFront != null){
                  let form = new FormData();
                  form.append('guestId', ID)
                  form.append('fileType', this.ineFileFront.type)
                  form.append('fileName', this.ineFileFront.name)
                  form.append('type', 'ine-front')
                  form.append('files', this.ineFileFront)

                  this.$root.authApi.post('files', form, {
                    headers: {
                      "Content-Type": "multipart/form-data"
                    }
                  }).then(() => {
                      this.isLoading = false;
                  }).catch(() => {
                      //this.message = error.response.data.flash
                      this.showDismissibleAlert = true
                      this.isLoading = false;
                  })
                }

                if( this.ineFileBack != null){
                  // do your submit logic here
                  let formData = new FormData();
                  formData.append('files',this.ineFileBack)
                  formData.append('guestId',ID)
                  formData.append('type','ine-back')

                  this.$root.authApi.post(
                      `files`,
                      formData,
                      { headers:{'Content-Type': 'multipart/form-data'}}
                  ).then(() => {
                      this.isLoading = false
                  }).catch( err => {
                    this.isLoading = false
                    console.error(err)
                  });
                }

                this.$router.push('step-three')
              }else{
                if(this.passportFile != null){
                  // do your submit logic here
                  this.submitStatus = 'PENDING'
                  this.isLoading = true
                  let formData = new FormData();
                  formData.append('files',this.passportFile)
                  formData.append('guestId',ID)
                  formData.append('type','passport')

                  this.$root.authApi.post(
                      `files`,
                      formData,
                      { headers:{'Content-Type': 'multipart/form-data'}}
                  ).then(() => {
                      this.isLoading = false
                      this.$router.push('step-three')
                  }).catch( err => console.error(err));
                }else{
                  this.submitStatus = 'ERROR'
                  this.isLoading = false
                  return
                }
              }
            }else{
              this.isLoading = false
              this.$router.push('step-three')
            }
          }else{
            this.isLoading = false
            this.$router.push('step-three')
          }
    },
    fileDeleted(){
      this.disabledTypeFile = true
      this.getUser()
    },
    saveReservationholder () {
      var ID  = this.$store.state.reservation.clientId;
      //var billing = null

        const data = {
            name: this.nombre,
            lastName: this.apellido,
            phone: this.phone,
            email: this.email,
            age: this.age,
            title: 'title',
            country: this.country,
            state: this.state,
            city: this.city,
            address: this.address,
            address2: this.address2,
            zipCode: this.zipCode,
            personalId: this.ID,
            personalIdType: this.typeFileSelection,
            billing: this.billing,
            requireBilling: this.$refs.billingComponent.$v.status.$model ? true : false,
            lang: this.$store.state.lang,
        }

        if(this.changeMode){
          this.$root.authApi.put(`guests?id=${ID}`, data
          ).then(() => {
            this.isLoading = false
          }).catch(err => {
              this.isLoading = false
              console.error(err)
          });
        }

        this.saveFile(ID)
    },
    onChangeFileIneFront (file) {
        if(this.ineFileBack && file){
            this.hasDocument = true
        }else{
            this.hasDocument = false
        }
        this.changeModeFile = true
        this.ineFileFront = file
    },
    onChangeFileIneBack (file) {
        if(this.ineFileFront && file){
            this.hasDocument = true
        }else{
            this.hasDocument = false
        }
        this.changeModeFile = true
        this.ineFileBack=file
    },
    onChangeFilePassport (file) {
        if(file){
            this.hasDocument = true
        }else{
            this.hasDocument = false
        }
        this.changeModeFile = true
        this.passportFile = file
    },
    fileSelected (selected) {
        this.changeMode = true
        this.typeFileSelection = selected
        if(selected == 'ine_ife'){
            if(this.$refs.fileIneFront && this.$refs.fileIneFront.file){
                this.$refs.fileIneFront.clearLocalFiles()
            }
            if(this.$refs.fileIneBack && this.$refs.fileIneBack.file){
                this.$refs.fileIneBack.clearLocalFiles()
            }
        }else if(selected == 'pasaporte'){
            if(this.$refs.filepassport && this.$refs.filepassport.file){
                this.$refs.filepassport.clearLocalFiles()
            }
        }
        this.ineFileFront = null
        this.ineFileBack = null
        this.passportFile = null
    },
    onFocusValidate(validate){
        // 1. Loop the keys
        for (let key in Object.keys(validate.$v)) {
            // 2. Extract the input
            const input = Object.keys(validate.$v)[key];

            // 3. Remove special properties
            if (input.includes("$")) return false;

            // 4. Check for errors
            if (validate.$v[input].$error) {
                // 5. Focus the input with the error
                validate.$refs[input].focus();

                // 6. Break out of the loop
                break
            }
        }
    },
    validateAll(){
      this.isLoading = true
      this.$v.$touch()
      this.$refs.direccionComponent.$v.$touch()
      if(this.$v.$invalid){
          this.isLoading = false
          this.onFocusValidate(this)
          return false
      }if(this.$refs.direccionComponent.$v.$invalid){
          this.isLoading = false
          this.onFocusValidate(this.$refs.direccionComponent)
          return false
      }
      if(this.$refs.billingComponent.$v.status.$model){
          //verificamos que todos los datos requeridos esten
          if(!this.$refs.billingComponent.$v.$invalid && !this.$refs.billingComponent.$refs.direccionComponent.$v.$invalid){
              //agregamos los datos del billing al objeto para guardarlo en el array
              const {
                  ciudad,
                  clientCodeRfc,
                  selected,
                  codigopostal,
                  pais,
                  email,
                  nombreCompleto,
                  telefono,
                  estado,
                  linea1,
                  linea2
              } = this.$refs.billingComponent

              let obj = {
                  address: linea1,
                  address2: linea2,
                  city: ciudad,
                  country: pais,
                  email: email,
                  name: nombreCompleto,
                  phone: telefono,
                  state: estado,
                  zipCode: codigopostal,
                  rfc: clientCodeRfc,
                  businessName: selected
              }
              this.billing = obj
          }else{
              this.$refs.billingComponent.$v.$touch()
              this.$refs.billingComponent.$refs.direccionComponent.$v.$touch()
              if(this.$refs.billingComponent.$v.$invalid){
                  this.isLoading = false
                  this.onFocusValidate(this.$refs.billingComponent)
                  return false
              }
              if(this.$refs.billingComponent.$refs.direccionComponent.$v.$invalid){
                  this.isLoading = false
                  this.onFocusValidate(this.$refs.billingComponent.$refs.direccionComponent)
                  return false
              }
          }
      }
      if(this.$refs.documentSelection.$v.$invalid){
          this.$refs.documentSelection.$v.$touch()
          this.onFocusValidate(this.$refs.documentSelection)
          this.isLoading = false
          return false
      }
      //let file = this.fileId ? this.fileId.length == 2 ? false : true : false
      if(this.typeFileSelection == 'ine_ife'){
          let found = false;
          let found2 = false;
          if(this.fileId){
            for(let i = 0; i < this.fileId.length; i++) {
                if (this.fileId[i].type == 'ine-front') {
                    found = true;
                }else if (this.fileId[i].type == 'ine-back'){
                  found2 = true;
                }
            }
          }

          if(!found && this.ineFileFront == null){
              this.$refs.fileIneFront.$v.$touch()
              this.isLoading = false
              return false
          }else if(!found2 && this.ineFileBack == null){
              this.$refs.fileIneBack.$v.$touch()
              this.isLoading = false
              return false
          }
      }else if(this.typeFileSelection == 'pasaporte'){
          let found = false;
          if(this.fileId){
            for(let i = 0; i < this.fileId.length; i++) {
                if (this.fileId[i].type == 'passport') {
                    found = true;
                }
            }
            if(!found && this.passportFile == null){
                this.$refs.filepassport.$v.$touch()
                this.isLoading = false
                return false
            }
          }else{
            if(this.passportFile == null){
                this.$refs.filepassport.$v.$touch()
                this.isLoading = false
                return false
            }
          }
      }
      return true
  },
    onSubmit() {
      //ev.preventDefault()
      this.isLoading = true
      let validate = this.validateAll()

      if(validate){
          this.saveReservationholder()
      }
    },
    onReset(evt) {
      evt.preventDefault()
    },
  },
  created() {
    this.isLoading = false
  },
  mounted(){
    this.getUser();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },
  update() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .text-reader {
    position: relative;
    overflow: hidden;
    display: inline-block;

    /* Fancy button looking */
    border: 2px solid black;
    border-radius: 5px;
    padding: 8px 12px;
    cursor: pointer;
  }
  .text-reader input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
  }
</style>
