<template>
    <b-card-footer class="p-0" style="position: sticky; bottom: 0; width: -webkit-fill-available;z-index: 2;">
        <div size="lg">
            <b-button-group class="btn-block">
                <b-button class="btn-step" squared @click="$router.back()">
                    <b-icon-arrow-left class="left"/> {{$t('footer.return')}}
                </b-button>
                <b-button 
                    class="btn-step"
                    type="submit" 
                    :disabled="submitStatus === 'PENDING'"
                    squared>
                        {{ nextValue }} <b-icon-arrow-right />
                </b-button>
            </b-button-group>
        </div>
    </b-card-footer>
</template>

<script>
export default {
  name: 'checkinfootermiddle',
  props: {
    step: String,
    nextValue: String,
    submitStatus: String
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
