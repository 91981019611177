<template>
  <div class="hello">
    <h1>Zavia ERP</h1>
  </div>
</template>

<script>
export default {
  name: 'Home',
  props: {},
  components:{
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
