<template>
	<div>
		<b-alert v-model="notfound" variant="danger" dismissible>
			No se encontro tu reserva
        </b-alert>
		<loading-overlay :active="loading" s-full-page />
	</div>
</template>

<script>
export default {
	data(){
		return {
			loading:true,
			notfound:false,
			message:''
		}
	},
	components: {
	},
	computed: {
	},
	mounted(){
		if (this.$route.query['res-token']) {
			this.$root.login(this.$route.query['res-token']).then(res => {
				this.loading = false
				if (!res.success) {
					this.notfound = true
				}
			})
		}else{
			this.loading = false
			this.notfound = true
		}
	}
}
</script>

<style scoped>

</style>
