<template>
  <div class="row justify-content-md-center">
    <div class="col-md-6 col-lg-5 col-12">
      <b-form @submit.prevent="onSubmit" v-if="show">
      <b-card no-body>
        <check-in-header />

        <navigation-bar :active="5"/>
        <b-card-body style="min-height: 500px">
          <b-card-title>Confirma tus datos y firma de conformidad</b-card-title>
          <div class="row">
              <b-form-group
                class="col-md-6 col-lg-6 col-12" 
                style="text-align: left"
                id="input-reservacion"
                label="Reservacion"
                label-for="reservacion"
              >
                <b-form-input
                  id="reservacion"
                  v-model="reservacion"
                  type="text"
                  required
                  placeholder=""
                  disabled
                  size="sm"
                ></b-form-input>
              </b-form-group>

              <b-form-group 
                class="col-md-6 col-lg-6 col-12" 
                style="text-align: left"
                id="input-fecha-reservacion" 
                label="Fecha Reservacion" 
                label-for="fecha-reservacion">
                <b-form-input
                  id="fecha-reservacion"
                  v-model="fechaReservacion"
                  required
                  placeholder=""
                  disabled
                  size="sm"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="col-md-6 col-lg-6 col-12"
                style="text-align: left"
                id="input-entrada"
                label="Entrada"
                label-for="entrada"
              >
                <b-form-input
                  id="entrada"
                  v-model="entrada"
                  type="text"
                  required
                  placeholder=""
                  disabled
                  size="sm"
                ></b-form-input>
              </b-form-group>

              <b-form-group 
                class="col-md-6 col-lg-6 col-12" 
                style="text-align: left"
                id="input-salida" 
                label="Salida" 
                label-for="salida">
                <b-form-input
                  id="salida"
                  v-model="salida"
                  required
                  placeholder=""
                  disabled
                  size="sm"
                ></b-form-input>
              </b-form-group>
              <b-form-group 
                class="col-md-6 col-lg-6 col-12" 
                style="text-align: left"
                id="input-habitaciones" 
                label="Habitaciones" 
                label-for="habitaciones">
                <b-form-input
                  id="habitaciones"
                  v-model="habitaciones"
                  required
                  placeholder=""
                  disabled
                  size="sm"
                ></b-form-input>
              </b-form-group>
                <h5 class="col-12 mt-3" style="text-align: left">Datos de invitado</h5>
                <b-form-group 
                  class="col-12" 
                  style="text-align: left"
                  id="input-nombre-completo" 
                  label="Nombre Completo" 
                  label-for="nombre-completo">
                  <b-form-input
                      :disabled="true"
                      id="nombre-completo"
                      size="sm"
                      v-model.trim="nombreCompleto"
                      placeholder=""
                  ></b-form-input>
                  </b-form-group>
                  <b-form-group 
                  class="col-12" 
                  style="text-align: left"
                  id="input-group-2" 
                  label="Correo" 
                  label-for="correo">
                  <b-form-input
                      :disabled="true"
                      id="correo"
                      size="sm"
                      v-model.trim="correo"
                      placeholder=""
                  ></b-form-input>
                  </b-form-group>
                  <b-form-group 
                  class="col-12" 
                  style="text-align: left"
                  id="input-group-2" 
                  label="Telefono" 
                  label-for="telefono">
                  <b-form-input
                      :disabled="true"
                      id="telefono"
                      size="sm"
                      v-model.trim="telefono"
                      placeholder=""
                  ></b-form-input>
                </b-form-group>
                <div class="col-12">
                  <div class="row">
                    <h5 class="col-12" style="text-align: left">Documentos</h5>
                    <div v-if="personalIdType == 'pasaporte' || personalIdType == 'ine_ife'" class="col-6 mb-2">
                      <div class="h2 card m-2 p-1">
                        <b-icon-file-earmark-check class="rounded mx-auto" variant="success" />
                      </div>
                    </div>
                    <div v-if="personalIdType == 'ine_ife'" class="col-6 mb-2">
                      <div class="h2 card m-2 p-1">
                        <b-icon-file-earmark-check class="rounded mx-auto" variant="success" />
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </b-card-body>

        <b-card-footer class="p-0" style="position: sticky; bottom: 0; width: -webkit-fill-available;">
          <check-in-footer-middle step="sign" nextValue="FIRMAR"/>
        </b-card-footer>
      </b-card>
      </b-form>
    </div>
  </div>
</template>

<script>
import CheckInHeader from '@/components/header/CheckInHeader'
import CheckInFooterMiddle from '@/components/footer/CheckInFooterMiddle'
import NavigationBar from '@/components/bodycard/navigationbar'

import { mapState } from 'vuex'

export default {
  name: 'resume',
  components: {
    CheckInHeader,
    CheckInFooterMiddle,
    NavigationBar
  },
  computed: {
    ...mapState(['reservation'])
  },
  data() {
    return {
      reservacion: '',
      fechaReservacion: '',
      entrada: '',
      salida: '',
      habitaciones: null,
      selected: false,
      show: true,
      nombreCompleto: '',
      correo: '',
      telefono: null,
      hasDocument: false,
      personalIdType: ''
    }
  },
  methods: {

    onChangeDataTitular(titular){
      this.telefono = titular.telefono
      this.correo = titular.correo
    },
    async getDataUser () {
      //this.$store.dispatch('getReservation').then((res) =>{
        const { 
          refId,
          resDate,
          startDate,
          endDate,
          rooms,
        } = this.$store.state.reservation;

        this.reservacion= refId
        this.fechaReservacion= resDate
        this.entrada= startDate
        this.salida= endDate
        this.habitaciones= rooms
      //})

      const idNewGuest = localStorage.getItem('idNewGuest')
      await this.$store.dispatch('sendBookData', {id:idNewGuest})
      const {
        name,
        lastName,
        email,
        phone,
        hasDocument,
        personalIdType
      } = this.$store.state.bookData;

      this.nombreCompleto = name + ' ' + lastName
      this.correo = email
      this.telefono = phone 
      this.hasDocument = hasDocument
      this.personalIdType = personalIdType
    },
    onSubmit(){
      this.$router.push('sign')
    }
  },
  created: function() {
    this.getDataUser()
  },
  mounted(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>