<template>
<div class="row justify-content-md-center">
	<div class="col-md-6 col-lg-5 col-12">
	<b-form @submit.prevent="onSubmit" @reset="onReset">
	<b-card no-body>
		<check-in-header />
		<b-card-body style="min-height: 500px">
		<b-card-title>{{$t('reservationData.title')}}</b-card-title>
		<b-row>
			<b-form-group
				class="col-md-6 col-lg-6 col-12"
				style="text-align: left"
				id="input-reservacion"
				:label="$t('resume.reservation')"
				label-for="reservacion"
				:description="submitStatus === 'ERROR' ? 'Ingresar datos requeridos':''"
			>
				<b-form-input
				disabled: disabled
				:class="{'is-invalid':$v.reservacion.$error, 'is-valid':!$v.reservacion.$invalid}"
				id="reservacion"
				size="sm"
				v-model.trim="$v.reservacion.$model"
				type="text"
				placeholder=""
				></b-form-input>
			</b-form-group>

			<b-form-group
				class="col-md-6 col-lg-6 col-12"
				style="text-align: left"
				id="input-fecha-reservacion"
				:label="$t('resume.reservationDate')"
				label-for="fecha-reservacion"
				:description="submitStatus === 'ERROR' ? 'Ingresar datos requeridos':''">
				<b-form-input
				disabled: disabled
				:class="{'is-invalid':$v.fechaReservacion.$error, 'is-valid':!$v.fechaReservacion.$invalid}"
				id="fecha-reservacion"
				size="sm"
				v-model="fechaReservacion"
				placeholder=""
				></b-form-input>
			</b-form-group>

			<b-form-group
				class="col-md-6 col-lg-6 col-12"
				style="text-align: left"
				id="input-entrada"
				:label="$t('resume.entry')"
				label-for="entrada"
			>
				<b-form-input
				disabled: disabled
				:class="{'is-invalid':$v.entrada.$error, 'is-valid':!$v.entrada.$invalid}"
				id="entrada"
				size="sm"
				v-model="entrada"
				type="text"
				placeholder=""
				></b-form-input>
			</b-form-group>

			<b-form-group
				class="col-md-6 col-lg-6 col-12"
				style="text-align: left"
				id="input-salida"
				:label="$t('resume.departure')"
				label-for="salida">
				<b-form-input
				disabled: disabled
				:class="{'is-invalid':$v.salida.$error, 'is-valid':!$v.salida.$invalid}"
				id="salida"
				size="sm"
				v-model="salida"
				placeholder=""
				></b-form-input>
			</b-form-group>
			<b-form-group
				class="col-md-6 col-lg-6 col-12"
				style="text-align: left"
				id="input-habitaciones"
				:label="$t('resume.roomsNumber')"
				label-for="habitaciones">
				<b-form-input
				disabled: disabled
				:class="{'is-invalid':$v.habitaciones.$error, 'is-valid':!$v.habitaciones.$invalid}"
				id="habitaciones"
				size="sm"
				v-model="habitaciones"
				placeholder=""
				></b-form-input>
			</b-form-group>
			<h5 class="col-12" style="text-align: left">{{$t('personalInformation.titleInfo')}}</h5>
		</b-row>
			<b-row>
				<b-col sm>
				<b-form-group :label="$t('personalInformation.fullName')" >
					<b-form-input
						class="is-valid"
						disabled
						size="sm"
						v-model="nombreCompleto"
					/>
				</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col sm>
				<b-form-group :label="$t('personalInformation.phone')" >
					<b-form-input
						class="is-valid"
						disabled
						size="sm"
						v-model="telefono"
					/>
				</b-form-group>
				</b-col>
				<b-col sm>
				<b-form-group :label="$t('personalInformation.email')" >
					<b-form-input
						class="is-valid"
						disabled
						size="sm"
						v-model="correo"
					/>
				</b-form-group>
				</b-col>
			</b-row>
		</b-card-body>
		<b-card-footer class="p-0" style="position: sticky; bottom: 0; width: -webkit-fill-available;">
		<check-in-footer-start :submitStatus="submitStatus"/>
		</b-card-footer>
	</b-card>
	</b-form>
	</div>
	<loading-overlay :active="isLoading" :is-full-page="true" />
</div>
</template>

<script>
import CheckInHeader from '@/components/header/CheckInHeader'
import CheckInFooterStart from '@/components/footer/CheckInFooterStart'
import { required, minLength } from 'vuelidate/lib/validators'

import { mapState } from 'vuex'

export default {
name: 'WebCheckIn',
components: {
	CheckInHeader,
	CheckInFooterStart,
},
props: {},
computed: {
	...mapState(['dataUser']),
},
data() {
	return {
	reservacion: '',
	fechaReservacion: '',
	entrada: '',
	salida: '',
	habitaciones: null,
	nombreCompleto: '',
	correo: '',
	telefono: '',
	show: false,
	submitStatus: null,
	disabled: true,
	isLoading: true
	}
},
validations: {
	reservacion: {
		required,
		minLength: minLength(1)
	},
	fechaReservacion: {
		required,
	},
	entrada: {
		required,
	},
	salida: {
		required,
	},
	habitaciones: {
		required,
	},
},
methods: {
	async getDataUser () {
	await this.$root.loadReservation()
	this.reservacion= this.$store.state.reservation.refId
	this.fechaReservacion= this.$store.state.reservation.resDate
	this.entrada= this.$store.state.reservation.startDate
	this.salida= this.$store.state.reservation.endDate
	this.habitaciones= this.$store.state.reservation.rooms
	this.nombreCompleto= this.$store.state.reservation.client.name +' '+this.$store.state.reservation.client.lastName
	this.correo= this.$store.state.reservation.client.email ? this.$store.state.reservation.client.email : ''
	this.telefono= this.$store.state.reservation.client.phone
	this.isLoading = false
	},
	onSubmit() {
	this.$v.$touch()
	this.isLoading = true
	if (this.$v.$invalid) {
		this.submitStatus = 'ERROR'
	} else {
		// do your submit logic here
		this.submitStatus = 'PENDING'
		setTimeout(() => {
		this.submitStatus = 'OK'
		this.isLoading = false
		this.$router.push('webcheckin/step-two')
		}, 500)
	}
	},
	onReset(evt) {
		evt.preventDefault()
	}
},
mounted(){
	window.scrollTo({ top: 0, behavior: 'smooth' });
	this.getDataUser()
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
