import api from './api'


export default {
    async getUserData (path, token) {
        const local = localStorage.getItem("resToken");
        const form = new FormData();
        if(!local){
            form.append('resToken', token);
            const { data } = await api.getToken(form)      
            if(data.error){
                return data
            }else{
                localStorage.setItem("resToken", data.token);
                return data
            }
        }
        else{
            return await api.getReservation(local)
        }
    },
    async getGuests (path, params = '') {
        const localToken = localStorage.getItem("resToken");
        if(localToken){
            const data  = await api.getGuests(localToken, {path, params:params})

            return data
        }
    },
    async addUploadGuest (path, params = '', data){
        const localToken = localStorage.getItem("resToken");
        if(localToken){
            return await api.addUploadGuest(localToken, {path, params:params}, data)
            .then(res => {
                return res
            })
        }
    },
    async getGuestById (path, params = '') {
        const localToken = localStorage.getItem("resToken");
        if(localToken){
            return await api.getGuestById(localToken, {path, params:params})
            .then(res => {
                return res
            })
        }
    },
    async deleteGuestById (path, params = '') {
        const localToken = localStorage.getItem("resToken");
        if(localToken){
            return await api.deleteGuestById(localToken, {path, params:params})
            .then(res => {
                return res
            })
        }
    },
    async generalSettings (path, params = '') {
        const localToken = localStorage.getItem("resToken");
        if(localToken){
            return await api.generalSettings(localToken, {path, params:params})
            .then(res => {
                return res
            }).catch(err => console.error(err))
        }
    },
    async saveReservationholder (path, params = '', data, type) {
        const localToken = localStorage.getItem("resToken");
        if(localToken){
            return await api.saveReservationholder(localToken, {path, params:params}, data, type)
            .then(res => {
                return res
            }).catch(err => console.error(err))
        }
    },
    async deleteFileUser (path, params= '') {
        const localToken = localStorage.getItem("resToken");
        if(localToken){
            return await api.deleteFileUser(localToken, {path, params:params})
            .then(res => {
                return res
            }).catch(err => console.error(err))
        }
    },
    async finishprecheckin (path){
        const localToken = localStorage.getItem("resToken");
        if(localToken){
            return await api.finishprecheckin(localToken, {path})
            .then(res => {
                return res
            }).catch(err => console.error(err))
        }
    }
}