<template>
    <b-form class="row" @submit.prevent="handleOk">
        <b-form-group
            class="col-6"
            style="text-align: left"
            id="input-group-2"
            :label="$t('personalInformation.name')"
            label-for="input-2"
            :description="submitStatus === 'ERROR' ? 'Ingresar datos requeridos':''">
            <b-form-input
                :disabled="$store.state.owner"
                @change="changeMode = true"
                :class="{'is-invalid':$v.name.$error, 'is-valid':!$v.name.$invalid}"
                id="input-2"
                size="sm"
                v-model.trim="$v.name.$model"
                ref="name"
                placeholder=""
            ></b-form-input>
        </b-form-group>
        <b-form-group
            class="col-6"
            style="text-align: left"
            id="input-group-2"
            :label="$t('personalInformation.lastName')"
            label-for="input-2"
            :description="submitStatus === 'ERROR' ? 'Ingresar datos requeridos':''">
            <b-form-input
                :disabled="$store.state.owner"
                @change="changeMode = true"
                :class="{'is-invalid':$v.lastname.$error, 'is-valid':!$v.lastname.$invalid}"
                id="input-2"
                size="sm"
                v-model="lastname"
                ref="lastname"
                placeholder=""
            ></b-form-input>
        </b-form-group>
        <b-form-group
            class="col-6"
            style="text-align: left"
            id="input-phone"
            :label="$t('personalInformation.phone')"
            label-for="phone"
            :description="submitStatus === 'ERROR' ? 'Ingresar datos requeridos':''">
            <b-form-input
                :disabled="$store.state.owner"
                @change="changeMode = true"
                :class="{'is-invalid':$v.phone.$error, 'is-valid':!$v.phone.$invalid}"
                id="phone"
                size="sm"
                v-model="phone"
                ref="phone"
                placeholder=""
            ></b-form-input>
        </b-form-group>
        <b-form-group
            class="col-6"
            style="text-align: left"
            id="input-email"
            :label="$t('personalInformation.email')"
            label-for="email"
            :description="submitStatus === 'ERROR' ? 'Ingresar datos requeridos':''">
            <b-form-input
                :disabled="$store.state.owner"
                @change="changeMode = true"
                :class="{'is-invalid':$v.email.$error, 'is-valid':!$v.email.$invalid}"
                id="email"
                size="sm"
                v-model.trim="email"
                ref="email"
                placeholder=""
            ></b-form-input>
        </b-form-group>
        <b-form-group
            class="col-6"
            style="text-align: left"
            id="input-age"
            :label="$t('personalInformation.age')"
            label-for="age">
            <b-form-input
                :disabled="$store.state.owner"
                @change="changeMode = true"
                :class="{'is-invalid':$v.age.$error, 'is-valid':!$v.age.$invalid}"
                id="age"
                size="sm"
                v-model="age"
                ref="age"
                placeholder=""
            ></b-form-input>
        </b-form-group>
        <direccion ref="direccionComponent" @onChangeDirection="onChangeDirection" :update="direccionData" />
        <billing ref="billingComponent" @onChangeBilling="onChangeBilling" :update="billing ? billing : {
                                            name: name,
                                            lastName: lastname,
                                            age: age,
                                            email: email,
                                            phone: phone,
                                            country: country,
                                            city: city,
                                            state: state,
                                            zipCode: zipCode,
                                            address: address,
                                            address2: address2}"/>

        <div class="row mt-3 ml-0 mr-1">
            <div class="col-12">
                <h5>{{$t('documents.titleDocuments')}}</h5>
                <document-selection ref="documentSelection" @fileSelected="fileSelected" @onChangeDocument="onChangeDocument" :update="documentUpdate"/>
            </div>
        </div>
        <div v-if="typeFileSelection === 'ine_ife'" class="col-12 mt-3 ml-0 mr-1">
            <h5 class="col-12" style="text-align: left">{{$t('documents.file')}}</h5>
            <div class="row">
                <div class="col-12">
                    <file-upload :id="Number(ID)" :fileId="fileId ? fileId.find(({type}) => type === 'ine-front') : null" :hasDocument="hasDocument" ref="fileIneFront" :typeFile="$t('documents.ineFront')" fileTypeId="INE/IFE_front" @onChangeFIle="onChangeFileIneFront" @fileDeleted="fileDeleted"/>
                </div>
                <div class="col-12">
                    <file-upload :id="Number(ID)" :fileId="fileId ? fileId.find(({type}) => type === 'ine-back') : null" :hasDocument="hasDocument" ref="fileIneBack" :typeFile="$t('documents.ineBack')" fileTypeId="INE/IFE_back" @onChangeFIle="onChangeFileIneBack" @fileDeleted="fileDeleted"/>
                </div>
            </div>
        </div>
        <div v-if="typeFileSelection === 'pasaporte'" class="col-12 mt-3 ml-0 mr-1">
            <h5 class="col-12" style="text-align: left">{{$t('documents.file')}}</h5>
            <div class="row">
                <div class="col-12">
                    <file-upload :id="Number(ID)" :fileId="fileId ? fileId.find(({type}) => type === 'passport') : null" :hasDocument="hasDocument" ref="filepassport" :typeFile="$t('documents.passport')" fileTypeId="passport" @onChangeFIle="onChangeFilePassport" @fileDeleted="fileDeleted"/>
                </div>
            </div>
        </div>
        <loading-overlay :active="isLoading" :is-full-page="true" />
    </b-form>
</template>

<script>
import Billing from './Billing'
import Direccion from './Direccion'
import DocumentSelection from './DocumentSelection'
import FileUpload from '@/components/bodycard/fileupload/FileUpload'
import { required, minValue, email } from 'vuelidate/lib/validators'
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'Modal',
    components: {
        Billing,
        Direccion,
        DocumentSelection,
        FileUpload
    },
    computed: {
        ...mapGetters(['getBookId','getBookData']),
        ...mapState(['bookData']),
    },
    data() {
        return {
            submitStatus: '',
            name: '',
            lastname: '',
            phone: '',
            email: '',
            age: 0,
            state:'',
            city: '',
            country: null,
            address: '',
            address2: '',
            zipCode: '',
            ID: null,
            direction: false,
            typeFileSelection: null,
            options: [
                { value: null, text: 'Please select an option' },
                { value: 'ine_ife', text: this.$t('documents.ine') },
                { value: 'pasaporte', text: this.$t('documents.passport') },
            ],
            isLoading: false,
            ineFileFront: null,
            ineFileBack: null,
            passportFile: null,
            changeMode: false,
            changeModeFile: false,
            hasDocument: false,
            billing: {},
            fileId: [],
            getBilling: null,
            direccionData: {},
            documentUpdate: {},
            disabledTypeFile: false
        }
    },
    watch: {
        age: function(val){
            this.$emit('setAge', val )
        }
    },
    validations: {
        name: {
            required,
        },
        lastname: {
            required,
        },
        phone: {
            required,
        },
        email: {
            required,
            email
        },
        age: {
            required,
            minValue: minValue(1)
        },
    },
    methods: {
        onChangeFileIneFront (file) {
            if(this.ineFileBack && file){
                this.hasDocument = true
            }else{
                this.hasDocument = false
            }
            this.changeModeFile = true
            this.ineFileFront = file
        },
        onChangeFileIneBack (file) {
            if(this.ineFileFront && file){
                this.hasDocument = true
            }else{
                this.hasDocument = false
            }
            this.changeModeFile = true
            this.ineFileBack=file
        },
        onChangeFilePassport (file) {
            if(file){
                this.hasDocument = true
            }else{
                this.hasDocument = false
            }
            this.changeModeFile = true
            this.passportFile = file
        },
        fileSelected (selected) {
            this.changeMode = true
            this.typeFileSelection = selected
            if(selected == 'ine_ife'){
                if(this.$refs.fileIneFront && this.$refs.fileIneFront.file){
                    this.$refs.fileIneFront.clearLocalFiles()
                }
                if(this.$refs.fileIneBack && this.$refs.fileIneBack.file){
                    this.$refs.fileIneBack.clearLocalFiles()
                }
            }else if(selected == 'pasaporte'){
                if(this.$refs.filepassport && this.$refs.filepassport.file){
                    this.$refs.filepassport.clearLocalFiles()
                }
            }
            this.ineFileFront = null
            this.ineFileBack = null
            this.passportFile = null
        },
        fileDeleted(){
            this.disabledTypeFile = true
            this.updateModal()
        },
        onChangeDocument(documents){
            this.changeMode = true
            this.ID = documents.ID
        },
        onChangeBilling(mode){
        this.changeMode = mode
        },
        onChangeDirection(direction){
            this.changeMode = true
            this.address = direction.linea1
            this.address2 = direction.linea2
            this.country = direction.pais
            this.state = direction.estado
            this.city = direction.ciudad
            this.zipCode = direction.codigopostal
        },
        updateModal () {
            const { stateGuestEvent } = this.$store.state
            if(stateGuestEvent == 'edit'){
                let {
                    name,
                    phone,
                    email,
                    lastName,
                    country,
                    city,
                    state,
                    age,
                    address,
                    address2,
                    zipCode,
                    billing,
                    personalId,
                    personalIdType,
                    hasDocument,
                    documents
                } = this.bookData

                this.name     = name
                this.phone    = phone
                this.email    = email
                this.age      = age
                this.lastname = lastName
                this.country  = country
                this.city     = city
                this.state    = state
                this.address  = address
                this.address2 = address2
                this.zipCode  = zipCode
                this.ID = personalId
                this.typeFileSelection = personalIdType
                this.hasDocument = hasDocument
                this.fileId     = documents
                this.billing = Object.keys(billing).length === 0 ? null : billing
                this.direccionData = {
                    country: country,
                    city: city,
                    state: state,
                    zipCode: zipCode,
                    address: address,
                    address2: address2
                }
                this.documentUpdate = {
                    ID: personalId,
                    typeFileSelection: personalIdType,
                    fileId: documents
                }
            }else {
                this.resetModal()
            }
        },
        resetModal () {
            this.name     = ''
            this.phone    = ''
            this.email    = ''
            this.age      = null
            this.lastname = ''
            this.country  = ''
            this.city     = ''
            this.state    = ''
            this.address  = ''
            this.address2 = ''
            this.zipCode  = ''
            this.hasDocument = false
            this.billing = null
            this.fileId = null
        },
        async handleOk (bvModalEvt) {
            bvModalEvt.preventDefault()
            var id = this.bookData.id
            const { stateGuestEvent } = this.$store.state
            let validate = await this.validateAll()
            if(validate){
                this.handleSubmit(id, stateGuestEvent)
            }else{
                return
            }
        },
        saveFiles(ID){
            this.isLoading = true
            if(this.typeFileSelection  != ''){
                if(this.changeModeFile){
                    if(this.typeFileSelection === 'ine_ife'){
                        if(this.ineFileFront != null){

                            let formData = new FormData();
                            formData.append('files',this.ineFileFront)
                            formData.append('guestId',ID)
                            formData.append('type','ine-front')

                            this.$root.authApi.post(
                                `files`,
                                formData,
                                { headers:{'Content-Type': 'multipart/form-data'}}
                            ).then(() => {
                                this.isLoading = false
                            }).catch( err => console.error(err));

                        }
                        if(this.ineFileBack != null){

                            let formData = new FormData();
                            formData.append('files',this.ineFileBack)
                            formData.append('guestId',ID)
                            formData.append('type','ine-back')

                            this.$root.authApi.post(
                                `files`,
                                formData,
                                { headers:{'Content-Type': 'multipart/form-data'}}
                            ).then(() => {
                                this.isLoading = false
                            }).catch( err => console.error(err));

                        }

                        this.$bvModal.hide('modal-1')
                    }else{
                        if(this.passportFile != null){
                            // do your submit logic here
                            this.submitStatus = 'PENDING'

                            let formData = new FormData();
                            formData.append('files',this.passportFile)
                            formData.append('guestId',ID)
                            formData.append('type','passport')

                            this.$root.authApi.post(
                                `files`,
                                formData,
                                { headers:{'Content-Type': 'multipart/form-data'}}
                            ).then(() => {
                                this.submitStatus = 'OK'
                                this.isLoading = false
                                this.$bvModal.hide('modal-1')
                            }).catch( err => {
                                this.isLoading = false
                                console.error(err)
                            });

                        } else {
                            this.isLoading = false
                            //this.submitStatus = 'ERROR'
                            return
                        }
                    }
                }else{
                    this.isLoading = false
                    this.$bvModal.hide('modal-1')
                }
            }else{
                this.isLoading = false
                //this.$router.push('step-three')
                this.$bvModal.hide('modal-1')
            }
        },
        onFocusValidate(validate){
            // 1. Loop the keys
            for (let key in Object.keys(validate.$v)) {
                // 2. Extract the input
                const input = Object.keys(validate.$v)[key];

                // 3. Remove special properties
                if (input.includes("$")) return false;

                // 4. Check for errors
                if (validate.$v[input].$error) {
                    // 5. Focus the input with the error
                    validate.$refs[input].focus();

                    // 6. Break out of the loop
                    break
                }
            }
        },
        validateAll(){
            this.isLoading = true
            if(this.$v.$invalid){
                this.$v.$touch()
                this.isLoading = false
                this.onFocusValidate(this)
                return false
            }if(this.$refs.direccionComponent.$v.$invalid){
                this.$refs.direccionComponent.$v.$touch()
                this.isLoading = false
                this.onFocusValidate(this.$refs.direccionComponent)
                return false
            }
            if(this.$refs.billingComponent.$v.status.$model){
                //verificamos que todos los datos requeridos esten
                if(!this.$refs.billingComponent.$v.$invalid && !this.$refs.billingComponent.$refs.direccionComponent.$v.$invalid){
                    //agregamos los datos del billing al objeto para guardarlo en el array
                    const {
                        ciudad,
                        clientCodeRfc,
                        selected,
                        codigopostal,
                        pais,
                        email,
                        nombreCompleto,
                        telefono,
                        estado,
                        linea1,
                        linea2
                    } = this.$refs.billingComponent

                    let obj = {
                        address: linea1,
                        address2: linea2,
                        city: ciudad,
                        country: pais,
                        email: email,
                        name: nombreCompleto,
                        phone: telefono,
                        state: estado,
                        zipCode: codigopostal,
                        rfc: clientCodeRfc,
                        businessName: selected
                    }
                    this.getBilling = obj
                }else{
                    this.$refs.billingComponent.$v.$touch()
                    this.$refs.billingComponent.$refs.direccionComponent.$v.$touch()
                    if(this.$refs.billingComponent.$v.$invalid){
                        this.isLoading = false
                        this.onFocusValidate(this.$refs.billingComponent)
                        return false
                    }
                    if(this.$refs.billingComponent.$refs.direccionComponent.$v.$invalid){
                        this.isLoading = false
                        this.onFocusValidate(this.$refs.billingComponent.$refs.direccionComponent)
                        return false
                    }
                }
            }
            if(this.$refs.documentSelection.$v.$invalid){
                this.$refs.documentSelection.$v.$touch()
                this.onFocusValidate(this.$refs.documentSelection)
                this.isLoading = false
                return false
            }
            if(this.typeFileSelection === 'ine_ife'){
                let found = false;
                let found2 = false;
                if(this.fileId){
                    for(let i = 0; i < this.fileId.length; i++) {
                        if (this.fileId[i].type == 'ine-front') {
                            found = true;
                        }else if (this.fileId[i].type == 'ine-back'){
                        found2 = true;
                        }
                    }
                }

                if(!found && this.ineFileFront == null){
                    this.$refs.fileIneFront.$v.$touch()
                    this.isLoading = false
                    return false
                }else if(!found2 && this.ineFileBack == null){
                    this.$refs.fileIneBack.$v.$touch()
                    this.isLoading = false
                    return false
                }
            }else if(this.typeFileSelection === 'pasaporte'){
                let found = false;
                if(this.fileId){
                    for(let i = 0; i < this.fileId.length; i++) {
                        if (this.fileId[i].type == 'passport') {
                            found = true;
                        }
                    }
                }
                if(!found && this.passportFile == null){
                    this.$refs.filepassport.$v.$touch()
                    this.isLoading = false
                    return false
                }
            }
            return true
        },
        saveSign(id) {
            this.isLoading = true
            const { minAgeSign } = this.$store.state.settings
            let age = this.age;
            if(age >= minAgeSign){
                let sign = this.$store.state.sign;
                let formData = new FormData();

                formData.append('files', sign)
                formData.append('guestId',id)
                formData.append('type', 'sign')
                if(sign){
                    this.$root.authApi.post(
                        `files`,
                        formData,
                        { headers:{'Content-Type': 'multipart/form-data'}}
                    ).then(() => {
                            this.isLoading = false
                            this.kill = true
                            this.Firmar = 'Firmado'
                            this.titleFooter = 'Finalizar Pre-CheckIn'
                    }).catch( err => console.error(err));
                }
            }else {
                this.isLoading = false
            }
        },
        handleSubmit(id, stateGuestEvent){
            // do your submit logic here

            const data = {
                name: this.name,
                lastName: this.lastname,
                phone: this.phone,
                email: this.email,
                age: this.age,
                title: 'title',
                country: this.country,
                state: this.state,
                city: this.city,
                address: this.address,
                address2: this.address2,
                zipCode: this.zipCode,
                personalId: this.ID,
                personalIdType: this.typeFileSelection,
                billing: this.getBilling,
                requireBilling: this.$refs.billingComponent.$v.status.$model ? true : false
            }

            const idNewGuest = localStorage.getItem('idNewGuest')

            if(this.changeMode){
                if(stateGuestEvent == 'create'){
                    data.bookId = this.getBookId
                    this.$root.authApi.post('guests', data)
                    .then((res) =>{
                        if(res.status == 200){
                            if(res.data !== ""){
                                localStorage.setItem("idNewGuest", res.data.guestId)
                                this.isLoading = false
                                this.saveSign(res.data.guestId)
                                this.saveFiles(res.data.guestId)
                            }else{
                                this.isLoading = false
                                this.saveSign()
                                this.saveFiles(id)
                            }
                        }else{
                            this.isLoading = false
                            return
                        }
                    })
                }else if(stateGuestEvent == 'edit' || idNewGuest){
                    //params = {id: id, data}
                    this.$root.authApi.put(`guests?id=${id}`, data)
                    .then((res) =>{
                        if(res.status == 200){
                            if(res.data !== ""){
                                this.isLoading = false
                                this.saveFiles(res.data.guestId)
                            }else{
                                this.isLoading = false
                                localStorage.setItem("idNewGuest", id)
                                this.saveFiles(id)
                            }
                        }else{
                            this.isLoading = false
                            return
                        }
                    })

                }
            }if(stateGuestEvent == 'edit'){
                this.isLoading = false
                if(this.$store.state.owner){
                    const { reservation } = this.$store.state
                    data.bookId = this.getBookId,
                    data.clientId = reservation.clientId

                    this.$root.authApi.post(`guests`, data)
                    .then((res) =>{
                        if(res.status == 200){
                            if(res.data !== ""){
                                this.isLoading = false
                                this.saveSign(res.data.guestId)
                                this.saveFiles(res.data.guestId)
                            }else{
                                this.isLoading = false
                                this.saveSign(id)
                                localStorage.setItem("idNewGuest", id)
                                this.saveFiles(id)
                            }
                        }else{
                            this.isLoading = false
                            return
                        }
                    })
                }else{
                    this.saveSign(id)
                    this.saveFiles(id)
                }
            }else{
                this.isLoading = false
                return
            }

        }
    },
    created: function(){
        this.updateModal()
    },
    mounted(){
        if(this.billing){
            if(Object.keys(this.billing).length !== 0){
                this.$refs.billingComponent.asignDataBilling()
                this.$refs.billingComponent.status = true
            }
        }
    },
    updated: function(){
        if(this.billing){
            if(Object.keys(this.billing).length !== 0){
                this.$refs.billingComponent.asignDataBilling()
                this.$refs.billingComponent.status = true
            }
        }
    }
}
</script>

<style scoped>

</style>
