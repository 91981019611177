import Vue from 'vue'
import App from './App.vue'
import routes from '@/routes'
import axios from 'axios'
import i18n from './i18n'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import store from '@/store'

//start our loader component to use it in all the project
Vue.component('loading-overlay', Loading)
Vue.config.productionTip = false

new Vue({
	i18n,
  router: routes,
  render: h => h(App),
  store,
  data(){
	const api = axios.create({
		baseURL: process.env.VUE_APP_API_URL,
		// timeout: 1000,
		headers: {}
	});
	return {
		api,
	}
  },
  computed : {
	authApi(){
		const authApi = axios.create({
			baseURL: process.env.VUE_APP_API_URL,
			// timeout: 1000,
			headers: {
				'Authorization':`Bearer ${this.$store.state.resToken}`,
			}
		});
		return authApi
	}
  },
  methods : {
	loadBaseStates(){
		// carga settings
		this.authApi.get('settings').then(res => {
			this.$store.commit('setSettings',res.data);
		}).catch(err => console.error(err))
	},
	login(resToken){
		return new Promise((resolve) => {
			this.$root.api.post('/login',{ resToken }).then(res => {
				this.$store.dispatch('setResToken',res.data.token)
				this.$root.loadBaseStates()
				this.$router.push('webcheckin')
				resolve({success:true,message:''})
			}).catch(error => {
				if(error.response.data.flash.errorCode == 'statusAccepted'){
					//cambio accepted errorCode
					resolve({success:false, message:'Ha Finalizado con exito el proceso'})
					this.$router.push('thanks')
				}
				resolve({success:false, message:error.response.data.flash.errorCode})
			})
		})
	},
	async loadReservation(){
		if (this.$store.state.reservation === null) {
			const res = await this.authApi.get('/reservations')
			this.$store.commit('setReservation',res.data);
			this.$store.commit('setLang',res.data.client.lang)
			this.$i18n.locale = res.data.client.lang
		}
	}
  },
  created(){
	const resToken = localStorage.getItem("resToken",null);
	if (resToken) {
		this.$store.dispatch('setResToken',resToken);
		this.loadBaseStates()
	}
  }
}).$mount('#app')
