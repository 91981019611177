<template>
    <div class="justify-content-md-center" style="display: flex;">
    <b-alert
        style="position: fixed; z-index: 3000;top: 0;"
        :show="dismissCountDown"
        dismissible
        variant="warning"
        @dismissed="dismissCountDown=0"
        @dismiss-count-down="countDownChanged"
    >
        <p>{{$t('alerts.messageConnectionError')}}</p>
        <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
        ></b-progress>
    </b-alert>
    </div>
</template>

<script>
export default {
    name: 'AlertCountdown',
    data() {
        return {
            dismissSecs: 15,
            dismissCountDown: 0,
        }
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
    }
}
</script>