<template>
    <b-modal
        :no-close-on-backdrop="true"
        id="modal-1"
        :ok-only="cancel"
        :ok-disabled="disabledOk"
        :title="title"
        ref="modal"
        @show="updateModal"
        @hidden="hideModal"
        @ok="handleOk">
        <add-upload-guest @hideModal="hideModal" @setAge="setAge" ref="addUploadGuest"/>
        <b-card-body v-if="(age >= $store.state.settings.minAgeSign) && !(this.reservation.clientId == bookData.id)">
          <b-card-title>Firma</b-card-title>
            <b-form>
                <sign-drawer
                    @bookDeleteSign="bookDeleteSign"
                    @sign="sign"
                    :killCheckIn="kill"
                    :isReadyToUpload="isReadyToUpload"
                    :Firmar="Firmar"
                />
            </b-form>
        </b-card-body>
    </b-modal>
</template>

<script>
import AddUploadGuest from './AddUploadGuest';
import SignDrawer from './signdrawer/Index';
import {mapState} from 'vuex';

export default {
    name: 'Modal',
    components:{
        AddUploadGuest,
        SignDrawer
    },
    data(){
        return {
            cancel: true,
            showSign: true,
            Firmar: this.$t('sign.sign'),
            kill: false,
            disabledOk: false,
            age: 0,
            isReadyToUpload: false,
        }
    },
    watch: {
        age: function(age){
            if(this.$store.state.stateGuestEvent == 'edit'){
                this.disabledOk = false
            }else{
                if(age <= this.$store.state.settings.minAgeSign){
                    this.disabledOk = false
                }else{
                    this.disabledOk = true
                }
            }

        }
    },
    computed:{
        title: function() {
            return this.$store.state.stateGuestEvent == 'edit' ?
                this.$store.state.owner ? this.$t('modalInfo.titleOwnerEdit') : this.$t('modalInfo.titleGuestEdit')
            :
                this.$store.state.owner ? this.$t('modalInfo.titleOwner') : this.$t('modalInfo.titleGuest')
        },
        ...mapState(['bookData', 'owner','reservation'])
    },
    methods: {
        updateModal () {
            const { isSigned } = this.bookData;
            this.kill = isSigned;
            this.showSign = !isSigned;
            this.disabledOk = this.$store.state.owner ? false : !isSigned;
            this.isReadyToUpload = false
            this.$store.state.sign = null
        },
        hideModal () {
            this.$store.state.isModalActive = false
            this.$store.state.ownerExist = false
            this.$emit('getGuests')
        },
        setAge(age){
            this.age = age
        },
        sign(sign){
            this.$store.state.sign = sign
            this.isReadyToUpload = true
            this.disabledOk = false
        },
        handleOk (bvModalEvt) {
            if(this.$store.state.owner && this.$store.state.ownerExist){
                this.$bvModal.hide('modal-1')
            }else{
                this.$refs.addUploadGuest.handleOk(bvModalEvt)
            }
        },
        bookDeleteSign(){
            this.$root.authApi.get(`guests?id=${this.bookData.id}`).then(res => {
                res.data.id = this.bookData.id
                //mutation to update the book data
                this.$store.commit('setBookData', res.data);

                this.updateModal()
            }).catch(err => {
                console.error(err)
            })
        }
    },
}
</script>
