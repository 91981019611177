<template>
  <div class="row justify-content-md-center">
    <div class="col-md-6 col-lg-5 col-12">
      <b-card no-body :style="descriptionStyles">
        <check-in-header />
        <b-card-body >
          <b-card-title>{{$t('sign.title')}}</b-card-title>
            <b-form v-if="show">
                <sign-drawer
                  @bookDeleteSign="bookDeleteSign"
                  @sign="sign"
                  :killCheckIn="kill"
                  :isReadyToUpload="isReadyToUpload"
                  :Firmar="Firmar"
                />
            </b-form>
        </b-card-body>
        <alert-countdown ref="alertCountdown"/>
        <check-in-footer-end :titleFooter="titleFooter" :killCheckIn="kill"/>
      </b-card>
    </div>
    <loading-overlay :active="isLoading" :is-full-page="true" />
  </div>
</template>

<script>
import CheckInHeader from '@/components/header/CheckInHeader'
import CheckInFooterEnd from '@/components/footer/CheckInFooterEnd'
import SignDrawer from '@/components/bodycard/signdrawer/Index'
import AlertCountdown from '@/components/bodycard/alerts/AlertCountdown'

const screenHeight = screen.height;

export default {
  name: 'Sign',
  components: {
    CheckInHeader,
    CheckInFooterEnd,
    SignDrawer,
    AlertCountdown
  },
  props: {},
  computed: {
    descriptionStyles: function () {
      return { minHeight: (screenHeight)+'px' };
    }
  },
  data() {
    return {
      kill: null,
      titleFooter: this.$t('footer.return'),
      show: true,
      isLoading: false,
      killCheckIn: false,
      Firmar: this.$t('sign.sign'),
      isReadyToUpload: false,
    }
  },
  methods: {
    sign(sign){
      this.isLoading = true
      var ID = null;

      const data = this.$store.state.reservation
      ID = data ? data.clientId : null;

      let formData = new FormData();
      formData.append('files',sign)
      formData.append('guestId',ID)
      formData.append('type','sign')

      this.$root.authApi.post(
          `files`,
          formData,
          { headers:{'Content-Type': 'multipart/form-data'}}
      ).then(() => {
        this.getmainGuest()
      }).catch( err => {
        this.isLoading = false
        this.activeAlert();
        console.error(err)
      });

    },
    activeAlert(){
      this.isLoading = false
      let button = document.createElement('button')
      button.type = 'button'
      button.onClick = this.$refs.alertCountdown.showAlert();
    },
    async getmainGuest(){
      this.isLoading = true
      const token = localStorage.getItem('idNewGuest')
      if(this.$store.state.isModalActive){
        try {
          let res = await this.$root.authApi(`guests?id=${token}`)
          this.isLoading = false
          if(res.data.isSigned){
            this.kill = true
            this.Firmar = this.$t('sign.signed')
            this.titleFooter = this.$t('sign.preCheckInFinish')
          }else{
            this.kill = false
            this.isReadyToUpload = false
            this.Firmar = this.$t('sign.sign')
            this.titleFooter = this.$t('sign.return')
          }
        } catch (error) {
          console.error(error)
          this.isLoading = true
        }
      }else{
        try {
          const res = await this.$root.authApi.get('reservations')
          this.$store.commit('setReservation',res.data);
          //await this.$store.dispatch('sendBookData', {id:idNewGuest})
          //const { bookData } = this.$store.state
          this.isLoading = false
          if(res.data.client.isSigned){
              this.kill = true
              this.Firmar = this.$t('sign.signed')
              this.titleFooter = this.$t('sign.preCheckInFinish')
          }else{
            this.kill = false
            this.isReadyToUpload = false
              this.Firmar = this.$t('sign.sign')
              this.titleFooter = this.$t('sign.return')
          }
        } catch (error) {
          this.isLoading = false
          console.error(error)
        }
      }
    },
    bookDeleteSign(){
      this.getmainGuest()
    },
  },
  created: function(){
    //this.getmainGuest()
  },
  mounted(){
    this.getmainGuest()
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
