<template>
  <div id="app" class="container-fluid">
    <transition>
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueFormWizard from 'vue-form-wizard'
import VueSignature from "vue-signature-pad";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

Vue.use(VueSignature);
Vue.config.productionTip = false;

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuelidate)
Vue.use(VueFormWizard)

export default {
  name: 'App',
  components: {
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 0px;
  margin-bottom: 0px;
}
.btn-step{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}
.btn-step .b-icon{
  position: absolute;
  right: 15px;
}
.btn-step .b-icon.left{
  right: auto;
  left: 15px;
}
</style>
