import Vue from 'vue';
import VueI18n from 'vue-i18n'
import en from '../locales/en'
import es from '../locales/es'

Vue.use(VueI18n)

const i18n = new VueI18n({
	locale: 'en',
	fallbackLocale: 'en',
	messages: {
		en,
		es,
		// es: require('../locales/es.json'),
	},
})

export default i18n
