<template>
  <div class="row justify-content-md-center">
    <div class="col-md-6 col-lg-5 col-12">
      <b-form @submit.prevent="onSubmit" v-if="show">
      <b-card no-body  :style="descriptionStyles">
        <check-in-header />
        <navigation-bar :active="4"/>
        <b-card-body style="min-height: 500px">
          <b-card-title>{{$t('termsAndCoditions.title')}}</b-card-title>
                <div class="row">
                  <div class="col-md-12">
                    <b-card no-body class="text-center">
                        <div class="p-2 bg-secondary text-light">
                            <span v-html="settings.termsConditions"></span>
                        </div>
                        <b-form-group
                          class="col-12 mt-3"
                          style="text-align: left;z-index: 1;"
                          :description="submitStatus === 'ERROR' ? 'Ingresar datos requeridos':''">
                          <b-form-checkbox
                            id="checkbox-1"
                            v-model.trim="$v.status.$model"
                            name="checkbox-1"
                            value="accepted"
                            @change="$v.status.$touch()"
                            unchecked-value="not_accepted"
                            :class="{'is-valid':$v.status.$dirty && !$v.status.$invalid}"
                          >
                            {{$t('termsAndCoditions.isAccepted')}}
                          </b-form-checkbox>
                        </b-form-group>
                    </b-card>
                  </div>
                </div>
        </b-card-body>
        <check-in-footer-middle step="resume" :nextValue="$t('footer.next')"  :submitStatus="submitStatus"/>
      </b-card>
      </b-form>
    </div>
    <loading-overlay :active="isLoading" :is-full-page="true" />
  </div>
</template>

<script>
import CheckInHeader from '@/components/header/CheckInHeader'
import CheckInFooterMiddle from '@/components/footer/CheckInFooterMiddle'
import NavigationBar from '@/components/bodycard/navigationbar'

import { mapState } from 'vuex'

const screenHeight = screen.height;

export default {
  name: 'step-four',
  components: {
    CheckInHeader,
    CheckInFooterMiddle,
    NavigationBar
  },
  props: {},
  computed: {
      ...mapState(['settings']),
      descriptionStyles: function () {
        return { minHeight: (screenHeight)+'px' };
      }
  },
  data() {
    return {
      show: true,
      status: false,
      submitStatus: null,
      isLoading: false
    }
  },
  validations: {
    status: {
      checked (val) {
        localStorage.removeItem('termsandconditions');
        localStorage.setItem("termsandconditions", val);
        return val
      }
    }
  },
  methods: {
    onSubmit() {
      this.isLoading = true
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        this.isLoading = false
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'
        setTimeout(() => {
          this.submitStatus = 'OK'
          this.isLoading = false
          this.$router.push('resume')
        }, 500)
      }
    },
  },
  created(){
    if(localStorage.getItem("termsandconditions") === 'accepted'){
      this.status = 'accepted'
    }
  },
  mounted(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
