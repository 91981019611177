// import config from './config'
import axios from 'axios'

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    // timeout: 1000,
    headers: {
    }
});

//axios.defaults.headers.common['Authorization'] = `Bearer ${base64.encode(email+':'+pass)}`;
//axios.defaults.headers.common['Content-Type'] = 'application/json';
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'DELETE, POST, GET, OPTIONS';
//axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With';

function getToken(form){
    return  axiosInstance('login', {
                method: 'post',
                data: form,
                mode: 'no-cors',
            }).then(function (response) {
                return response
            }).catch(err => console.error(err));
}

function getReservation (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axiosInstance('reservations', {
        method: 'GET',
    }).then(function (response) {
        return response
    }).catch(err => console.error(err));
}

function getGuests (token, payload) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axiosInstance(payload.path+payload.params, {
        method: 'GET',
    }).then(function (response) {
        return response
    }).catch(err => console.error(err));
}

function addUploadGuest (token, payload, data) {
    let form = new FormData();
    let method = 'POST';

    form.append('name', data.name)
    form.append('lastName', data.lastName) 
    form.append('phone',  Number(data.phone))
    form.append('email', data.email)
    form.append('age', data.age)
    form.append('title', data.title)
    form.append('country', Number(data.country))
    form.append('state', data.state)
    form.append('city', data.city)
    form.append('address1', data.address)
    form.append('address2', data.address2)
    form.append('promotions', '')
    form.append('personalId', data.personalId)
    form.append('personalIdType', data.personalIdType)
    form.append('zipCode', data.zipCode)
    if(data.billing != null){
        form.append('requireBilling', true)
        form.append('billing', JSON.stringify(data.billing))
    }else{
        form.append('requireBilling', false)
    }

    if(data.id) {
        //method = 'PUT'
        form.append('_method', 'PUT')
        form.append('id', data.id)
    } else{
        form.append('bookId', data.bookId)
    }
    
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    
    return axiosInstance(payload.path+payload.params, {
        method: method,
        data:form
    }).then(function (response) {
        return response
    }).catch(err => {
        console.error(err)
        return err
    });
}

function getGuestById (token, payload) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    
    return axiosInstance(payload.path+payload.params, {
        method: 'GET',
    }).then(function (response) {
        return response
    }).catch(err => console.error(err));
}

function deleteGuestById (token, payload) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    
    return axiosInstance(payload.path+payload.params, {
        method: 'DELETE',
    }).then(function (response) {
        return response
    }).catch(err => console.error(err));
}

function generalSettings (token, payload) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axiosInstance(payload.path+payload.params, {
        method: 'GET',
    }).then(function (response) {
        return response
    }).catch(err => console.error(err));
}

async function saveReservationholder (token, payload, data, params) {
    let form = new FormData();
    
    form.append('guestId', params.id)
    form.append('fileType', data.type)
    form.append('fileName', data.name)
    form.append('type', params.type)
    form.append('files', data)
    
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    return axios.post(payload.path, form, {
        header:{
         'Content-Type' : 'multipart/form-data',
         'Access-Control-Allow-Origin' : '*'
        }
    }).then(function (response) {
        return response
    }).catch(err => console.error(err));
}

async function deleteFileUser (token, payload) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axiosInstance(payload.path+payload.params, {
        method: 'DELETE',
    }).then(function (response) {
        return response
    }).catch(err => console.error(err));
}

async function finishprecheckin (token, payload){
    let form = new FormData();
    form.append('_method', 'PUT')
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axiosInstance(payload.path, {
        method: 'POST',
        data: form
    }).then(function (response) {
        localStorage.clear();
        return response
    }).catch(err => console.error(err));
}

export default { 
    getToken, 
    getReservation, 
    getGuests, 
    addUploadGuest, 
    getGuestById, 
    deleteGuestById, 
    generalSettings,
    saveReservationholder,
    deleteFileUser,
    finishprecheckin
}

/***
 * peticiones a la api rest 
 * /books -> info books y arreglo de huespedes
 * /guests -> by id 
    /guests/1 -> objeto huesped id 1 //consultar
    Luis Uicab16:06
    /rooms
    Omar Bermudes Figueroa17:10
    resToken parametro que recibe el login 
    https://onsenui.github.io/vue-onsenui-kitchensink/
    Omar Bermudes Figueroa17:48
    http://admin.zavia/pms/hotels/api_guests/login
    Tú17:51
    http://admin.zavia/pms/hotels/calendars/planner/
    http://admin.zavia/pms/hotels/api_guests/guests
Autorization Bearer token "token"
    http://admin.zavia/pms/hotels/api_guests/reservations
    Base de datos zaviaerp -> clients
 */
