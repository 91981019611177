<template>
    <div>
        <div v-if="killCheckIn">
            <p>{{$t('sign.signIsSaved')}}</p>
            <button type="button" @click="deleteSign">{{$t('buttons.deleteSign')}}</button>
        </div>
        <div v-else-if="isReadyToUpload">
            <p>{{$t('sign.signIsReadyToSave')}}</p>
        </div>
        <div v-else-if="!killCheckIn && (killCheckIn != null) || this.$store.state.stateGuestEvent == 'create'" class="row">
            <div class="col-12 mt-4">
                <b-card no-body>
                    <VueSignaturePad
                        id="signature"
                        width="100%"
                        height="250px"
                        ref="signaturePad"
                        :options="{onBegin, onEnd}"
                    />
                </b-card>
            </div>
            <div class="col-12 mt-3 text-center">
                <b-button :disabled="killCheckIn" @click="undo">{{$t('buttons.clearSign')}}</b-button>
                <b-button class="ml-2" :disabled="!activeDraw || killCheckIn" @click="save">{{$t('sign.sign')}}</b-button>
            </div>
        </div>
        <overlay
            ref="overlay"
            :bodyText="$t('sign.deleteSignMessage')"
            fileType="sign"
            @bookDeleteSign="bookDeleteSign"
        />
        <loading-overlay :active="isLoading" :is-full-page="true" />
    </div>
</template>

<script>
import Overlay from '../alerts/Overlay.vue';
import { mapState } from 'vuex';

export default {
    name: 'SignDrawer',
    props: {
        killCheckIn: Boolean,
        isReadyToUpload: Boolean,
        Firmar: String
    },
    components: {
        Overlay,
    },
    computed: {
        ...mapState(['reservation']),
    },
    data() {
        return {
            deleteId: null,
            activeDraw: false,
            ready: false,
            isLoading: true,
            isSigned: false
        }
    },
    methods: {
        undo() {
            this.activeDraw = false;
            this.$refs.signaturePad.undoSignature();
        },
        save() {
            //const { isEmpty, data }
            const { data } = this.$refs.signaturePad.saveSignature();

            let sign = this.dataURLtoFile(data, 'sign');
            this.$emit('sign', sign)

        },
        onBegin() {
            this.activeDraw = true;
            console.log('=== Begin ===');
        },
        onEnd() {
            console.log('=== End ===');
        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, {type:mime});
        },

        deleteSign(){
            this.$refs.overlay.onSubmit();
        },
        bookDeleteSign(){
            this.$emit('bookDeleteSign')
        }
    },
    mounted(){
        this.isLoading = false
    },
    updated(){
        this.isLoading = false
        //this let us use the canvas in mobile and modals, don't delete
        if(this.$store.state.isModalActive){
            this.$nextTick(() => {
                if((this.$refs.signaturePad != undefined)){
                    this.$refs.signaturePad.resizeCanvas();
                }
            })
        }
    }
}
</script>

<style scoped>
    #signature {
        border: double 3px transparent;
        border-radius: 5px;
        background-image: linear-gradient(white, white),
            radial-gradient(circle at top left, #4bc5e8, #9f6274);
        background-origin: border-box;
        background-clip: content-box, border-box;
    }
</style>
