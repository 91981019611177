<template>
    <div class="col-12">
        <b-form-group 
            class="mt-3" 
            style="text-align: left;z-index: 1;">
            <b-form-checkbox
            id="checkbox-1"
            v-model.trim="$v.status.$model"
            name="checkbox-1"
            :value="true"
            :unchecked-value="false"
            >
            {{$t('invoice.title')}}
            </b-form-checkbox>
        </b-form-group>
        <div v-if="status" class="row">
        <b-form-group 
            class="col-12" 
            style="text-align: left"
            id="input-group-2" 
            :label="$t('invoice.billingEmail')" 
            label-for="correo">
            <b-form-input
                :disabled="$store.state.owner"
                @keyup="onChangeBilling"
                :class="{'is-invalid':$v.email.$error, 'is-valid':!$v.email.$invalid}"
                id="correo"
                size="sm"
                v-model.trim="email"
                ref="email"
                placeholder=""
            ></b-form-input>
        </b-form-group>
        <b-form-group 
            class="col-12" 
            style="text-align: left"
            id="input-razon-social" 
            :label="$t('invoice.businessName')" 
            label-for="razon-social">
            <b-form-input
                :disabled="$store.state.owner"
                @keyup="onChangeBilling"
                :class="{'is-invalid':$v.selected.$error, 'is-valid':!$v.selected.$invalid}"
                id="client-code-rfc"
                size="sm"
                v-model.trim="selected"
                ref="selected"
                placeholder=""
            ></b-form-input>
        </b-form-group>
        <b-form-group 
            class="col-12" 
            style="text-align: left"
            id="input-client-code-rfc" 
            :label="$t('invoice.rfc')" 
            label-for="client-code-rfc">
            <b-form-input
                :disabled="$store.state.owner"
                @keyup="onChangeBilling"
                :class="{'is-invalid':$v.clientCodeRfc.$error, 'is-valid':!$v.clientCodeRfc.$invalid}"
                id="client-code-rfc"
                size="sm"
                v-model.trim="clientCodeRfc"
                ref="clientCodeRfc"
                placeholder=""
            ></b-form-input>
        </b-form-group>    
        </div>
        <div class="row">
        <direccion ref="direccionComponent" v-if="status" @onChangeDirection="onChangeDirection" :update="{
                                        country: this.pais,
                                        city: this.ciudad,
                                        zipCode: this.codigopostal,
                                        address: this.linea1,
                                        state: this.estado,
                                        address2: this.linea2}"/>
        </div>
    </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import Direccion from './Direccion'

export default {
    name: 'Billing',
    props:{
        update: Object
    },
    components: {
        Direccion
    },
    watch: {
        status: function() {
            this.asignDataBilling()
        }
    },
    data(){
        return { 
            status: false,
            submitStatus: '',
            linea1: null,
            linea2: null,
            paises: [],
            pais: null,
            estado: '',
            ciudad: '',
            codigopostal: '',
            nombreCompleto: '',
            name: '',
            lastName: '',
            email: '',
            telefono: null,
            razonSocial: '',
            clientCodeRfc: '',
            selected: ''
        }
    },
    validations: {
        status: {
            checked (val) {
                return val
            }
        },
        email: {
            required   
        },
        selected: {
            required,
            minLength: minLength(3)
        },
        clientCodeRfc: {
            required,
            minLength: minLength(3)
        }
    },
    methods: {
        onChangeBilling(){
            this.$emit('onChangeBilling', true)
        },
        onChangeDirection(direction){
            if(direction){
                this.linea1 = direction.linea1
                this.linea2 = direction.linea2
                this.pais = direction.pais
                this.estado = direction.estado
                this.ciudad = direction.ciudad
                this.codigopostal = direction.codigopostal
                this.$store.commit('billing', direction)
                this.$emit('onChangeBilling', true)
            }
        },
        asignDataBilling(){
            const {
                address, 
                address2, 
                city, 
                country,
                email,
                lastName,
                name,
                phone,
                state,
                zipCode,
                businessName,
                nombreCompleto,
                rfc } = this.update;

            this.linea1         = address
            this.linea2         = address2
            this.pais           = country
            this.estado         = state
            this.ciudad         = city
            this.codigopostal   = zipCode
            this.nombreCompleto = nombreCompleto
            this.name           = name
            this.lastName       = lastName
            this.email          = email
            this.telefono       = phone
            this.selected       = businessName
            this.clientCodeRfc  = rfc
        }
    },
}
</script>
