<template>
  <div class="row justify-content-md-center">
    <div class="col-md-6 col-lg-5 col-12">
      <b-form @submit.prevent="onSubmit" v-if="show">
      <b-card no-body>
        <check-in-header />

        <navigation-bar :active="5"/>
        <b-card-body style="min-height: 500px">
          <b-card-title>{{$t('resume.title')}}</b-card-title>
          <div class="row">
              <b-form-group
                class="col-md-6 col-lg-6 col-12"
                style="text-align: left"
                id="input-reservacion"
                :label="$t('resume.reservation')"
                label-for="reservacion"
              >
                <b-form-input
                  id="reservacion"
                  v-model="reservacion"
                  type="text"
                  required
                  placeholder=""
                  disabled
                  size="sm"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="col-md-6 col-lg-6 col-12"
                style="text-align: left"
                id="input-fecha-reservacion"
                :label="$t('resume.reservationDate')"
                label-for="fecha-reservacion">
                <b-form-input
                  id="fecha-reservacion"
                  v-model="fechaReservacion"
                  required
                  placeholder=""
                  disabled
                  size="sm"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="col-md-6 col-lg-6 col-12"
                style="text-align: left"
                id="input-entrada"
                :label="$t('resume.entry')"
                label-for="entrada"
              >
                <b-form-input
                  id="entrada"
                  v-model="entrada"
                  type="text"
                  required
                  placeholder=""
                  disabled
                  size="sm"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="col-md-6 col-lg-6 col-12"
                style="text-align: left"
                id="input-salida"
                :label="$t('resume.departure')"
                label-for="salida">
                <b-form-input
                  id="salida"
                  v-model="salida"
                  required
                  placeholder=""
                  disabled
                  size="sm"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="col-md-6 col-lg-6 col-12"
                style="text-align: left"
                id="input-habitaciones"
                :label="$t('resume.roomsNumber')"
                label-for="habitaciones">
                <b-form-input
                  id="habitaciones"
                  v-model="habitaciones"
                  required
                  placeholder=""
                  disabled
                  size="sm"
                ></b-form-input>
              </b-form-group>
                <div class="row mt-3">
                  <div class="col-12">
                  <h5 class="col-12" style="text-align: left">{{$t('personalInformation.titleInfo')}}</h5>
                    <div class="row ml-1 mr-1">
                      <b-form-group
                      class="col-12"
                      style="text-align: left"
                      id="input-nombre-completo"
                      :label="$t('personalInformation.fullName')"
                      label-for="nombre-completo">
                      <b-form-input
                          :disabled="true"
                          :class="{'is-invalid':$v.nombreCompleto.$error, 'is-valid':!$v.nombreCompleto.$invalid}"
                          id="nombre-completo"
                          size="sm"
                          v-model.trim="nombreCompleto"
                          placeholder=""
                      ></b-form-input>
                      </b-form-group>
                      <b-form-group
                      class="col-md-6 col-lg-6 col-12"
                      style="text-align: left"
                      id="input-group-2"
                      :label="$t('personalInformation.email')"
                      label-for="correo">
                      <b-form-input
                          :disabled="true"
                          :class="{'is-invalid':$v.correo.$error, 'is-valid':!$v.correo.$invalid}"
                          id="correo"
                          size="sm"
                          v-model.trim="correo"
                          placeholder=""
                      ></b-form-input>
                      </b-form-group>
                      <b-form-group
                      class="col-md-6 col-lg-6 col-12"
                      style="text-align: left"
                      id="input-group-2"
                      :label="$t('personalInformation.phone')"
                      label-for="telefono">
                      <b-form-input
                          :disabled="true"
                          :class="{'is-invalid':$v.telefono.$error, 'is-valid':!$v.telefono.$invalid}"
                          id="telefono"
                          size="sm"
                          v-model.trim="telefono"
                          placeholder=""
                      ></b-form-input>
                      </b-form-group>
                    </div>
                </div>
                </div>
<!--                 <div class="col-12">
                  <div class="row">
                    <h5 class="col-12" style="text-align: left">{{$t('documents.titleDocuments')}}</h5>
                    <div v-if="personalIdType == 'pasaporte' || personalIdType == 'ine_ife'" class="col-6 mb-2">
                      <div class="h2 card m-2 p-1">
                        <b-icon-file-earmark-check class="rounded mx-auto" variant="success" />
                      </div>
                    </div>
                    <div v-if="personalIdType == 'ine_ife'" class="col-6 mb-2">
                      <div class="h2 card m-2 p-1">
                        <b-icon-file-earmark-check class="rounded mx-auto" variant="success" />
                      </div>
                    </div>
                  </div>
                </div>
 -->          </div>
        </b-card-body>

        <b-card-footer class="p-0" style="position: sticky; bottom: 0; width: -webkit-fill-available;">
          <check-in-footer-middle step="sign" :nextValue="$t('footer.next')"/>
        </b-card-footer>
      </b-card>
      </b-form>
    </div>
    <loading-overlay :active="isLoading" :is-full-page="true" />
  </div>
</template>

<script>
import CheckInHeader from '@/components/header/CheckInHeader'
import CheckInFooterMiddle from '@/components/footer/CheckInFooterMiddle'
import NavigationBar from '@/components/bodycard/navigationbar'

import { required } from 'vuelidate/lib/validators'

export default {
  name: 'resume',
  components: {
    CheckInHeader,
    CheckInFooterMiddle,
    NavigationBar
  },
  props: {},
  data() {
    return {
      reservacion: '',
      fechaReservacion: '',
      entrada: '',
      salida: '',
      habitaciones: null,
      selected: false,
      show: true,
      nombreCompleto: '',
      telefono: null,
      correo: '',
      personalIdType: '',
      isLoading: false,
    }
  },
  validations: {
      nombreCompleto: {
        required,
      },
      correo: {
        required,
      },
      telefono: {
        required,
      }
  },
  methods: {

    onChangeDataTitular(titular){
      this.telefono = titular.telefono
      this.correo = titular.correo
    },
    async getDataUser () {
		await this.$root.loadReservation()

      const {
            refId,
            resDate,
            startDate,
            endDate,
            rooms,
          } = this.$store.state.reservation;

      const {
        name,
        lastName,
        email,
        phone,
        personalIdType
      } = this.$store.state.reservation.client;

      this.reservacion= refId
      this.fechaReservacion= resDate
      this.entrada= startDate
      this.salida= endDate
      this.habitaciones= rooms
      this.nombreCompleto = name +' '+ lastName
      this.correo = email
      this.telefono = phone
      this.personalIdType = personalIdType

    },
    onSubmit(){
      this.isLoading = true
      this.$router.push('sign')
    }
  },
  mounted: function() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.getDataUser()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
