<template>
	<b-card-header v-if="settings != null" class="pb-0" style="position: sticky; top: 0; z-index: 2; background-color: #f8f8f8;">
		<div class="row no-gutters">
			<div class="mb-0 col-4 pr-1 text-center col-logo">
				<b-img :src="settings.hotelLogo" :alt="settings.hotelName" fluid style="max-height: 70px"></b-img>
			</div>
			<div class="mb-0 col-6 title">
				<h5 class="mb-0">{{settings.hotelName}}</h5>
			</div>
			<div class="col-2 col-lang">
				<b-select class="langSelect ml-1" v-model="currentLang" size="sm" :options="langOpts"/>
			</div>
		</div>
		<p class="text-center mb-0">{{$t('header.title')}}</p>
	</b-card-header>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'checkinheader',
	data(){
		return {
			langOpts : [
				{value:'en',text:'EN'},
				{value:'es',text:'ES'},
			]
		}
	},
	computed: {
		...mapState(['settings', 'lang']),
		currentLang : {
			get(){
					return this.$store.state.lang
			},
			set(val){
				console.log(val)
				let lang = val == '' ? 'en' : val
				this.$store.commit('setLang',lang)
				this.$i18n.locale = lang
			}
		}
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.col-logo{
	display: flex;
	justify-content: flex-start;
}
.col-lang{
	display: flex;
	justify-content: flex-end;
}
.title{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.langSelect{
	width: auto;
}
</style>
