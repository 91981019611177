<template>
    <div class="">
        <div class="col-12">
            <b-form class="row" @submit.prevent="onSubmit">
            <b-form-group
                class="col-12"
                style="text-align: left"
                id="input-linea1"
                :label="$t('address.address')"
                label-for="linea1"
                :description="submitStatus === 'ERROR' ? 'Ingresar datos requeridos':''">
                <b-form-input
                    :disabled="$store.state.owner"
                    :class="{'is-invalid':$v.linea1.$error, 'is-valid':!$v.linea1.$invalid}"
                    id="linea1"
                    size="sm"
                    v-model.trim="linea1"
                    ref="linea1"
                    @keyup="onChangeDirection"
                    placeholder=""
                ></b-form-input>
                </b-form-group>
                <b-form-group
                class="col-12"
                style="text-align: left"
                id="input-linea2"
                :label="$t('address.address2')"
                label-for="linea2"
                :description="submitStatus === 'ERROR' ? 'Ingresar datos requeridos':''">
                <b-form-input
                    :disabled="$store.state.owner"
                    :class="{'is-invalid':$v.linea2.$error, 'is-valid':!$v.linea2.$invalid}"
                    id="linea2"
                    size="sm"
                    v-model.trim="linea2"
                    ref="linea2"
                    @keyup="onChangeDirection"
                    placeholder=""
                ></b-form-input>
                </b-form-group>
                <b-form-group
                class="col-md-6 col-lg-6 col-12"
                style="text-align: left"
                id="input-pais"
                :label="$t('address.country')"
                label-for="pais"
                :description="submitStatus === 'ERROR' ? 'Ingresar datos requeridos':''">
                    <b-form-select
                        :disabled="$store.state.owner"
                        :class="{'is-invalid':$v.selected.$error, 'is-valid':!$v.selected.$invalid}"
                        @change="onChangeDirection"
                        v-model.trim="selected"
                        :value="selected ? selected : null"
                        ref="selected"
                        :options="paises"
                        size="sm"
                        class="mt-0"></b-form-select>
                </b-form-group>
                <b-form-group
                class="col-md-6 col-lg-6 col-12"
                style="text-align: left"
                id="input-estado"
                :label="$t('address.state')"
                label-for="estado"
                :description="submitStatus === 'ERROR' ? 'Ingresar datos requeridos':''">
                <b-form-input
                    :disabled="$store.state.owner"
                    :class="{'is-invalid':$v.estado.$error, 'is-valid':!$v.estado.$invalid}"
                    id="estado"
                    size="sm"
                    v-model.trim="estado"
                    ref="estado"
                    @keyup="onChangeDirection"
                    placeholder=""
                ></b-form-input>
                </b-form-group>
                <b-form-group
                class="col-md-6 col-lg-6 col-12"
                style="text-align: left"
                id="input-ciudad"
                :label="$t('address.city')"
                label-for="ciudad"
                :description="submitStatus === 'ERROR' ? 'Ingresar datos requeridos':''">
                <b-form-input
                    :disabled="$store.state.owner"
                    :class="{'is-invalid':$v.ciudad.$error, 'is-valid':!$v.ciudad.$invalid}"
                    id="ciudad"
                    v-model.trim="ciudad"
                    ref="ciudad"
                    @keyup="onChangeDirection"
                    size="sm"
                    placeholder=""
                ></b-form-input>
                </b-form-group>
                <b-form-group
                class="col-md-6 col-lg-6 col-12"
                style="text-align: left"
                id="input-codigopostal"
                :label="$t('address.zipCode')"
                label-for="codigopostal"
                :description="submitStatus === 'ERROR' ? 'Ingresar datos requeridos':''">
                <b-form-input
                    :disabled="$store.state.owner"
                    :class="{'is-invalid':$v.codigopostal.$error, 'is-valid':!$v.codigopostal.$invalid}"
                    id="codigopostal"
                    v-model.trim="codigopostal"
                    ref="codigopostal"
                    @keyup="onChangeDirection"
                    size="sm"
                    placeholder=""
                ></b-form-input>
            </b-form-group>
            </b-form>
        </div>
    </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'

export default {
    name: 'Direccion',
    props:{
        update: Object
    },
    data(){
        return {
            submitStatus: '',
            linea1: null,
            linea2: null,
            paises: [],
            estado: '',
            ciudad: '',
            codigopostal: '',
            selected:  null
        }
    },
    watch: {
        update: function(newVal) { // watch it
            this.linea1 = newVal.address
            this.linea2 = newVal.address2
            this.estado = newVal.state
            this.ciudad = newVal.city
            this.codigopostal = newVal.zipCode
            this.selected = newVal.country
        }
    },
    computed: mapState({
        settings: state => {
            let newCountries = []

            newCountries[0] = { value: null, text: 'selecciona un pais...' }
            state.settings.countries.map((res, key) => {
                newCountries[key + 1] = {
                    value: res.value,
                    text: res.label,
                    a2: res.a2
                }
            })

            return newCountries
        }
    }),
    validations: {
        linea1: {
            required,
            minLength: minLength(3)
        },
        linea2: {
        },
        selected: {
            required,
        },
        estado: {
            required,
            minLength: minLength(3)
        },
        ciudad: {
            required,
            minLength: minLength(3)
        },
        codigopostal: {
            required,
            minLength: minLength(3)
        },
    },
    methods: {
        onSubmit(bvModalEvt){
            bvModalEvt.preventDefault()
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            }else{
                this.submitStatus = 'PENDING'
            }
        },
        onChangeDirection(){
            let direction = {
                linea1: this.linea1,
                linea2: this.linea2,
                pais: this.selected,
                estado: this.estado,
                ciudad: this.ciudad,
                codigopostal: this.codigopostal,
                error: this.$v.$invalid
            }

            this.$emit('onChangeDirection', direction)
        },
        generalSettings () {
            let { countries } = this.$store.state.settings
            let newCountries = []

            newCountries[0] = { value: null, text: 'selecciona un pais...' }
            countries.map((res, key) => {
                newCountries[key + 1] = {
                    value: res.value,
                    text: res.label,
                    a2: res.a2
                }
            })

            this.paises = newCountries

        },
        updateDireccion(){
            this.linea1 = this.update ? this.update.address : null,
            this.linea2 = this.update ? this.update.address2 : null,
            this.estado = this.update ? this.update.state : '',
            this.ciudad = this.update ? this.update.city : '',
            this.codigopostal = this.update ? this.update.zipCode : '',
            this.selected = this.update ? this.update.country ? this.update.country : null : null
        }
    },
    created(){
        this.generalSettings()
    },
    mounted: function () {
        this.updateDireccion()
    },
}
</script>
