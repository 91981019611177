<template>
  <div>
    <b-overlay :show="busy" no-wrap @shown="onShown" @hidden="onHidden">
        <template #overlay>
          <div v-if="processing" class="text-center p-4">
            <!-- <b-icon icon="cloud-upload" font-scale="4"></b-icon>
            <div class="mb-3">Processing...</div>
            <b-progress
              min="1"
              max="20"
              :value="counter"
              variant="success"
              height="3px"
              class="mx-n4 rounded-0"
            ></b-progress> -->
          <loading-overlay :active="true" />
          </div>
          <div
            v-else
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center p-3"
          >
            <p><strong id="form-confirm-label">{{ bodyText }}</strong></p>
            <div class="d-flex">
              <b-button variant="outline-danger" class="mr-3" @click="onCancel">
                Cancel
              </b-button>
              <b-button variant="outline-success" @click="onOK">OK</b-button>
            </div>
          </div>
        </template>
      </b-overlay>
      <alert-countdown ref="alertCountdown"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AlertCountdown from './AlertCountdown'

export default {
    name: 'Overlay',
    props: {
        bodyText: String,
        fileType: String
    },
    components: {
      AlertCountdown,
    },
    computed: {
        ...mapState(['bookData', 'reservation'])
    },
    data() {
      return {
        busy: false,
        processing: false,
        counter: 1,
        interval: null
      }
    },
    methods: {
      onShown() {
        // Focus the dialog prompt
        this.$refs.dialog.focus()
      },
      onHidden() {
        // In this case, we return focus to the submit button
        //this.$refs.forms.focus()
      },
      onSubmit() {
        this.processing = false
        this.busy = true
        
      },
      onCancel() {
        this.busy = false
      },
      deleteFile(id, documents){
        const sign = documents.filter(res => res.type == this.fileType)
        this.$root.authApi.delete(`files?id=${sign[0].id}`)
        .then(async (res) => {
            if(res.status === 200){
              this.$emit('bookDeleteSign')
                
              this.$nextTick(() => {
                  this.busy = this.processing = false
              })
                
            }
        })
        .catch(err => {  
          console.error(err)
          this.busy = this.processing = false
          let button = document.createElement('button')
          button.type = 'button'
          button.onclick = this.$refs.alertCountdown.showAlert();
        })
      },
      onOK() {
        this.counter = 1
        this.processing = true
        if(this.$store.state.isModalActive){
            this.deleteFile(this.bookData.id, this.bookData.documents)
        }else{
            this.deleteFile(this.reservation.clientId, this.reservation.client.documents)
        }
        
        
      }
    },
}
</script>