<template>
    <div class="row justify-content-md-center">
        <div class="col-md-6 col-lg-5 col-12">
        <b-card no-body  :style="descriptionStyles">
            <check-in-header />
            <b-card-body class="text-center">
            <h2>{{$t('thanks.title')}}</h2>
            <hr>
            <h4>{{$t('thanks.messageText')}}</h4>
            </b-card-body>
            <!-- <check-in-footer-end titleFooter="Cerrar" :finish="true"/> -->
        </b-card>
        </div>
    </div>
</template>

<script>
import CheckInHeader from '@/components/header/CheckInHeader'
// import CheckInFooterEnd from '@/components/footer/CheckInFooterEnd'

const screenHeight = screen.height;

export default {
    name: 'Sign',
    components: {
        CheckInHeader,
        // CheckInFooterEnd
    },
    computed: {
        descriptionStyles: function () {
            return { minHeight: (screenHeight)+'px' };
        }
    },
    mounted(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}
</script>

<style scoped>

</style>
