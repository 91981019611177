<template>
    <b-button 
        type="submit" 
        class="btn-step" 
        style="width:100%; border-radius: 0" 
        :disabled="submitStatus === 'PENDING'">
        {{$t('footer.next')}} <b-icon-arrow-right/>
    </b-button>
</template>

<script>
export default {
  name: 'CheckInFooter',
  props: {
    submitStatus: String
  },
}
</script>
