<template>
    <div>
        <b-modal 
        modal-class="modal-fullscreen"
        :id="'takePicture-'+fileTypeId" 
        title="Capturar Documentacion"
        ref="takePicture">
        <div id="webcam" style="position: relative">
            <button 
                class="btn btn-default" 
                style="position: absolute; right: 0; color:#FFFFFF;padding: 15px;font-size: xx-large;z-index: 1"
                @click="toggleFullScreen">X</button>
            <web-cam
                        ref="webcam"
                        :device-id="deviceId"
                        width="100%"
                        height="auto"
                        @started="onStarted"
                        @stopped="onStopped"
                        @error="onError"
                        @cameras="onCameras"
                        @camera-change="onCameraChange"
                    />      
            <div class="row" style="position: absolute;bottom: 0;width: 100%;">
                <div class="mx-auto">
                    <button 
                        class="btn btn-danger rounded-pill" 
                        style="padding: 14px 18px;" 
                        @click="savePicture()">
                        <b-icon icon="camera" font-scale="1"></b-icon>
                    </button> 
                </div> 
                <div class="col-md-12">
                    <select v-model="camera">
                        <option>-- Selecciona un Dispositivo--</option>
                        <option
                            v-for="device in devices"
                            :key="device.deviceId"
                            :value="device.deviceId"
                        >{{ device.label }}</option>
                    </select>
                </div>
            </div>
            
        </div>
        <div class="row">
            <div class="col-12">
                <h6>Captured Image</h6>
                <figure class="figure">
                    <img :src="img" class="img-responsive" width="100%" />
                </figure>
            </div>
        </div>
        </b-modal>
    </div>
</template>

<script>
import { WebCam } from "vue-web-cam";

export default {
    name: 'TakePicture',
    props: {
        fileTypeId: String
    },
    components: {
        WebCam
    },
    computed: {
        device: function() {
            return this.devices.find(n => n.deviceId === this.deviceId);
        }
    },    
    data() {
        return {
            img: null,
            camera: null,
            deviceId: null,
            devices: []
        }
    },
    watch: {
        camera: function(id) {
            this.deviceId = id;
        },
        devices: function() {
            // Once we have a list select the first one
            const [first] = this.devices;
            if (first) {
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
            }
        }
    },
    methods: {
        onCapture() {
            this.img = this.$refs.webcam.capture();
            let picture = this.dataURLtoFile(this.img, Date().toString());
            this.$emit('picture', picture)
        },
        onStarted(stream) {
            console.log("On Started Event", stream);
        },
        onStopped(stream) {
            console.log("On Stopped Event", stream);
        },
        onStop() {
            this.$refs.webcam.stop();
        },
        onStart() {
            this.$refs.webcam.start();
        },
        onError(error) {
            console.log("On Error Event", error);
        },
        onCameras(cameras) {
            this.devices = cameras;
            console.log("On Cameras Event", cameras);
            this.toggleFullScreen()
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), 
                n = bstr.length, 
                u8arr = new Uint8Array(n);
                
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            
            return new File([u8arr], filename, {type:mime});
        },
        savePicture(){
            this.onCapture()
            this.toggleFullScreen()
        },
        toggleFullScreen() {
            //document.fullscreenEnabled = document.fullscreenEnabled || document.mozFullScreenEnabled || document.documentElement.webkitRequestFullScreen;
            if (!document.fullscreenElement) {
                this.onStart()
                document.getElementById('webcam').requestFullscreen();
            } else {
                if (document.exitFullscreen) {
                    this.onStop()
                    document.exitFullscreen();
                    this.$bvModal.hide(`takePicture-${this.fileTypeId}`) 
                }
            }
        },
    },
    mounted(){
    }
}
</script>

<style scoped>
    .modal-fullscreen .modal-dialog {
        max-width: 100%;
        margin: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100vh;
        display: flex;
        position: fixed;
        z-index: 100000;
    }
</style>
