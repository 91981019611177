<template>
    <div class="row justify-content-md-center">
        <div class="col-md-6 col-lg-5 col-xs-12">
            <b-form @submit.prevent="onSubmit">
                <b-card no-body>
                    <check-in-header />
                    <div class="col-12 mt-3">
                        <div>
                            <h4>Habitacion</h4>
                            <h5>{{name}}</h5>
                        </div>
                        <add-upload-guest ref="addUploadGuest" />
                    </div>
                    <b-card-footer class="p-0" style="position: sticky; bottom: 0; width: -webkit-fill-available;">
                        <check-in-footer-guest ref="checkinfooter" titleFooter="Continuar" />
                    </b-card-footer>
                </b-card>
            </b-form>
        </div>   
    </div> 
</template>

<script>
import CheckInHeader from '@/components/header/CheckInHeader'
import AddUploadGuest from '@/components/bodycard/AddUploadGuest'
import CheckInFooterGuest  from '@/components/footer/CheckInFooterGuest'

export default {
    name:'Guest',
    data(){
        return {
            name: null
        }
    },
    components:{
        CheckInHeader,
        AddUploadGuest,
        CheckInFooterGuest
    },
    methods: {
        async onSubmit () {
            const { id } = JSON.parse(localStorage.getItem('token'))
            const { changeMode, changeModeFile } = this.$refs.addUploadGuest
            let validate = this.$refs.addUploadGuest.validateAll()
            if(validate){
                if(changeMode || changeModeFile){
                    try{
                        await this.$refs.addUploadGuest.handleSubmit(id, this.$store.state.stateGuestEvent)
                    }catch(err){
                        console.error(err)
                    }
                    this.$router.push('/guest/termsandconditions')
                }else {
                    this.$router.push('/guest/termsandconditions')
                }
            }
        }
    },
    created(){
        const bookId = this.$route.params.id
        this.name = this.$route.params.name
        if(this.$route.params){
            localStorage.setItem("resToken", this.$route.params.token)
            localStorage.setItem("token", JSON.stringify(this.$route.params))
            this.$store.dispatch('sendBookId', bookId)
            this.$store.state.stateGuestEvent = 'create'
        }
        const idNewGuest = localStorage.getItem('idNewGuest')
        if(idNewGuest){
            this.$root.authApi.get(`guests?id=${Number(idNewGuest)}`).then((res) => {
            res.data.id = Number(idNewGuest)                
            this.$store.state.bookData = res.data
            //this.$store.dispatch('sendBookData',{id:Number(idNewGuest)}).then(() => {
                this.$store.state.stateGuestEvent = 'edit'
                localStorage.removeItem("token")
                localStorage.setItem("token",JSON.stringify({token: this.$route.params.token, id: this.$route.params.id}))
                this.$refs.addUploadGuest.updateModal()
            })
        }
    },
    updated(){
        const idNewGuest = localStorage.getItem('idNewGuest')
        if(idNewGuest){
            this.$root.authApi.get(`guests?id=${Number(idNewGuest)}`).then((res) => {
            res.data.id = Number(idNewGuest)  
            this.$store.state.bookData = res.data
            //this.$store.dispatch('sendBookData',{id:Number(idNewGuest)}).then(() => {
                localStorage.removeItem("token")
                this.$store.dispatch('sendBookId', this.$route.params.id)
                localStorage.setItem("token",JSON.stringify({token: this.$route.params.token, id: this.$route.params.id}))
                this.$refs.addUploadGuest.updateModal() 
            })
        }
    },
    mounted(){    
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}
</script>

<style scoped>

</style>
