<template>
  <div class="hello">
    <h1> 404 </h1>
    <h3>Not Found</h3>
  </div>
</template>

<script>
export default {
  name: 'notfound',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
    justify-content: center;
    align-items: center;
}
h3 {
  margin: 40px 0 0;
}
</style>