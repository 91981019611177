<template>
    <div class="mx-auto col-md-6 mt-5">
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{message}}
        </b-alert>
        <b-form @submit.prevent="() => { onSubmit(); } " v-if="true">
        <b-form-group
            id="input-token"
            label="Ingresar token de reservacion:"
            label-for="token"
        >
            <b-form-input
            id="token"
            v-model.trim="token"
            type="text"
            placeholder=""
            required
            ></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="primary">Submit</b-button>
        </b-form>

        <loading-overlay :active="isLoading" :is-full-page="true" />
    </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'

export default {
    name: 'Login',
    data(){
        return{
            token: '',
            showDismissibleAlert: false,
            message: '',
            isLoading: false
        }
    },
    validations: {
      token: {
        required,
        minLength: minLength(3)
      },
    },
    methods: {
        onSubmit() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                // do your submit logic here
                this.isLoading = true;
                this.$root.login(this.token).then(res => {
                    if (!res.success){
                        this.message = res.message
                        this.showDismissibleAlert = true
                    }
                    this.isLoading = false;
                })
            }
        },
    },
}
</script>

<style scoped>

</style>
