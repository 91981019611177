<template>
	<div>
		<loading-overlay :active="$root.$store.state.settings === null" :is-full-page="true" />
		<transition name="fade" mode="out-in" v-if="$root.$store.state.settings !== null">
			<router-view></router-view>
		</transition>
	</div>
</template>

<script>

export default {
	name: "CheckInContent",
	mounted(){

	}
}
</script>

<style scoped>

</style>
