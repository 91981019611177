<template>
    <b-button-group class="btn-block">
        <b-button class="btn-step" squared @click="$router.back()">
            <b-icon-arrow-left class="left"/> {{$t('footer.return')}}
        </b-button>
        <b-button 
            type="submit" 
            class="m-0" 
            style="width:100%; border-radius: 0"
            @click="next">
            <div style="display: inline-flex">
                <h4>{{titleFooter}}</h4>
                <div class="h3">
                    <b-icon-arrow-right style="position: absolute; right: 15px;"/>
                </div>
            </div>
        </b-button>
    </b-button-group>
</template>

<script>
export default {
    name: 'CheckInFooter',
    props: {
        titleFooter: String,
    },
    data() {
        return {
            pushNext: 'termsandconditions'
        }
    },
    computed: {
        routes() {
            return this.$router.options.routes.find(r => r.name === 'contentGuest').children;
        },
        routeIndex() {
            return this.routes.findIndex(r => r.name === this.$route.name);
        },
        prev() {
            const route = this.routes[this.routeIndex - 1];
            return route && { name: route.name };
        },
    },
    methods:{
        next() {
            //const route = this.routes[this.routeIndex + 1];
            //this.$router.push({ name: route.name })
            //return route && { name: route.name };
            
        }
    }
}
</script>