<template>
	<div class="row justify-content-md-center">
	<div class="col-md-6 col-lg-5 col-12" v-if="$store.state.reservation !== null">
		<b-form @submit.prevent="onSubmit" @reset="onReset" v-if="show">
		<b-card no-body :style="descriptionStyles">
		<check-in-header />
		<b-card-body style="min-height: 500px">
			<p class="h5 mb-3"><strong>{{`${$store.state.reservation.client.name} ${$store.state.reservation.client.lastName}`}}</strong>  {{$t('sharedLink.title')}}</p>
			<hr>
			<!-- <p class="h6 mb-3">{{$t('reservationData.noReservation')}} #{{no_reservation}}</p> -->
			<div
				v-for="(room,i) in rooms"
				:key="room.bookId"
			>
				<b-button pill variant="info" v-if="false" size="sm" class="float-right" @click="getLink(room)">Compartir</b-button>
				<h5>#{{i+1}} {{room.name}} <small>{{room.paxesQuantity}} {{$t('books.people')}}</small></h5>
				<b-form-group
					v-for="(guest, key) in room.guests"
					:key="key"
					:label="`${$t('books.guest')} #${key + 1}`"
					>
					<b-input-group class="mt-1" size="sm">
						<b-input-group-prepend v-if="ownerId === guest.id">
							<b-input-group-text>{{$t('books.owner')}}</b-input-group-text>
						</b-input-group-prepend>
						<b-form-input
							id="input-2"
							v-model="guest.name"
							disabled
						/>
						<b-input-group-append v-if="ownerId !== guest.id" style="z-index: 1;">
							<b-button variant="outline-success" @click.prevent="sendBookData(guest)">{{$t('buttons.edit')}}</b-button>
							<b-button variant="outline-danger" @click.prevent="showModal(guest)">{{$t('buttons.delete')}}</b-button>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
				<div class="text-center">
					<b-button v-if="noMoreGuests ? !noMoreGuests.includes(room.bookId): false" size="sm" pill @click="sendBookid(room)" variant="primary"><b-icon-plus /> {{$t('books.guest')}}</b-button>
				</div>
				<hr>
			</div>
		</b-card-body>
		</b-card>
		</b-form>
	</div>
	<modal-add-guests @getGuests="getGuests"/>
	<b-modal
		id="modal-delete-user"
		ref="modal"
		title="Desea eliminar este invitado?"
		@hidden="resetModal"
		@ok="handleOk"
	>
		<p>{{nameGuest}}</p>
	</b-modal>
	<b-modal
		id="modal-2"
		title=""
		hide-footer>
		<b-input-group size="sm" prepend="link" class="mt-3">
		<b-form-input :value="newLink"></b-form-input>
		<b-input-group-append>
			<b-button
			@click="copiarAlPortapapeles"
			variant="info"
			v-b-tooltip.click="'copied'"><b-icon-link/> copiar-link</b-button>
		</b-input-group-append>
		<b-input-group-append>
			<a class="btn btn-success alignElements" target="_blank" :href="`https://api.whatsapp.com/send?text=visitanos%20en%20${newLink}`" data-action="share/whatsapp/share">
			<div id="whatsapp"></div> WhatsApp
			</a>
		</b-input-group-append>
		</b-input-group>
	</b-modal>
	<loading-overlay :active="isLoading" :is-full-page="true" />
	</div>
</template>

<script>
import CheckInHeader from '@/components/header/CheckInHeader'

import ModalAddGuests from '@/components/bodycard/ModalAddGuests'

const screenHeight = screen.height;
import { mapState } from 'vuex'

export default {
	name: 'step-three',
	components: {
	CheckInHeader,
	ModalAddGuests
	},
	props: {},
	computed: {
	ownerId(){
		return this.reservation!==null ? this.reservation.clientId : null
	},
	descriptionStyles: function () {
		return { minHeight: (screenHeight)+'px' };
	},
	...mapState(['reservation'])
	},
	data() {
	return {
		rooms : null,
		guests: '',
		show: true,
		submitStatus: null,
		getAllGuests: null,
		id: null,
		idDelete: null,
		nameGuest: '',
		newLink: '',
		isLoading: false,
		noMoreGuests: [],
		ownerExist: false,
		no_reservation: 0
	}
	},
	methods: {
	getLink (room) {
		const token = localStorage.getItem("resToken")
		this.$bvModal.show('modal-2')
		this.newLink = document.location.origin+'/guest/'+token+'/'+room.bookId+'/'+room.name.replace(' ', '-')
		this.copiarAlPortapapeles()
	},
	copiarAlPortapapeles() {
		var aux = document.createElement("input");
		aux.setAttribute("value", this.newLink);
		document.body.appendChild(aux);
		aux.select();
		document.execCommand("copy");
		document.body.removeChild(aux);
	},
	async updateReservationOwner(bookId){
		const res = await this.$root.authApi.get('/reservations')
		this.$store.commit('setReservation',res.data);
		this.$store.state.owner = true
		this.$store.state.bookId = bookId
		this.$store.state.bookData = this.$store.state.reservation.client
		this.$store.state.stateGuestEvent = 'edit'
		this.$bvModal.show('modal-1')
	},
	getGuests () {
		this.isLoading = true

		const { id } = this.$route.params
		this.$root.authApi.get(`books?id=${id}`).then(async (res) => {
		this.rooms = [res.data]
		this.noMoreGuests = []

		await this.$root.loadReservation()

		this.ownerExist = res.data.guests.find(res => res.id == this.reservation.clientId) ? false : true
		this.no_reservation = this.reservation.refId
		if(res.data.paxesQuantity == res.data.guests.length){
			this.noMoreGuests.push(res.data.bookId)
		}else if((res.data.paxesQuantity - 1) == (res.data.guests.length) && this.ownerExist){
			this.noMoreGuests.push(res.data.bookId)
		}

        this.isLoading = false
        // this.$store.commit('setReservation',res.data);
      }).catch((err) => {
        console.error(err)
        this.isLoading = false
      })
    },
    sendBookid(room) {
      this.$store.state.bookData = {}
      this.$store.state.isModalActive = true
      this.$store.state.owner = false
      this.$store.dispatch('sendBookId', room.bookId)
      this.$bvModal.show('modal-1')
    },
    sendBookData (value) {
      this.$root.authApi.get(`guests?id=${value.id}`)
      .then((res) => {
        this.$store.state.isModalActive = true
        res.data.id = value.id
        this.$store.state.bookData = res.data
        this.$store.state.stateGuestEvent = 'edit'
        this.$bvModal.show('modal-1')
      })
    },
    showModal (guest) {
      if(guest){
        this.idDelete = guest.id
        this.nameGuest = guest.name
        this.$bvModal.show('modal-delete-user')
      }
    },
    resetModal (){
      this.idDelete = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.deleteGuestById()
    },
    deleteGuestById(){
      this.$root.authApi.delete(`guests?id=${this.idDelete}`)
      .then(() =>{
        this.$nextTick(() => {
          this.getGuests();
          this.$bvModal.hide('modal-delete-user')
        })
      })
    },
    onSubmit() {
      this.$router.push('step-four')
    },
    onReset(evt) {
      evt.preventDefault()
    }
  },
  created: function () {
    this.getGuests()
  },
  mounted(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
</script>

<style scoped>
	@supports (-webkit-mask: url("")) or (mask: url("")) {
	.alignElements {
		display: flex;
		align-items: center;
	}
	#whatsapp {
		-webkit-mask: url(../assets/fonts/whatsapp-brands.svg);
		mask: url(../assets/fonts/whatsapp-brands.svg);
		background: #ffffff;
		width: 16px;
		height: 18px;
		float: left;
		margin-right: 5px;
	}
	}
</style>
